import {FC, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {useLogOutApi} from "@app/common/layouts/SecuredLayout/components/Header/MainMenu/LogoutLink/useLogOutApi";
import {LoginPagePath} from "@app/Login/LoginPage";

export const LogoutLink: FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const showErrorToast = useShowErrorToast();

    const logOutApi = useLogOutApi();

    const doLogOut = useCallback(async () => {
        try {
            await logOutApi();
            navigate(LoginPagePath);
        } catch (error: unknown) {
            showErrorToast(error as Error);
        }
    }, [logOutApi, navigate, showErrorToast]);

    return <a onClick={doLogOut}>{t('Header.logOut')}</a>
}
