import {FC, Fragment} from "react";
import {useWatch} from "react-hook-form";

import {AccountType} from "@app/common/model/TaxReturn/AccountType";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {NumericField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/NumericField";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {useFormFieldCb} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormFieldCb";
import {
    AccountTypeRadio
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/OverPaymentEdit/AccountTypeRadio/AccountTypeRadio";

export type AccountEditProps = {
    accountPath: 'socialInsurance.overPayment.account'|'socialInsurance.healthInsurance.bankAccount';
}

export const AccountEdit: FC<AccountEditProps> = ({accountPath}) => {
    const formFieldCb = useFormFieldCb();

    const accountTypePath = `${accountPath}.accountType` as const;
    const accountType = useWatch<TaxReturn, typeof accountTypePath>({name: accountTypePath});
    const optional = accountPath === 'socialInsurance.healthInsurance.bankAccount';

    return <Fragment>
        <AccountTypeRadio name={`${accountPath}.accountType`} />
        {accountType === AccountType.DOMESTIC && <FieldsRow>
            <NumericField
                name={`${accountPath}.domesticAccount.prefix`}
                customFormField={formFieldCb('account.domesticAccount.prefix')}
                optional={true}
            />
            <NumericField
                name={`${accountPath}.domesticAccount.number`}
                customFormField={formFieldCb('account.domesticAccount.number')}
                optional={optional}
            />
            <NumericField
                name={`${accountPath}.domesticAccount.code`}
                customFormField={formFieldCb('account.domesticAccount.code')}
                optional={optional}
            />
        </FieldsRow>}
        {accountType === AccountType.FOREIGN && <FieldsRow>
            <TextField
                name={`${accountPath}.foreignAccount.iban`}
                customFormField={formFieldCb('account.foreignAccount.iban')}
                optional={optional}
            />
            <TextField
                name={`${accountPath}.foreignAccount.swift`}
                customFormField={formFieldCb('account.foreignAccount.swift')}
                optional={optional}
            />
        </FieldsRow>}
    </Fragment>
}
