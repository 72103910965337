import {FC} from "react";
import {useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {TaxReturnCalculation} from "@app/common/model/TaxReturn/TaxReturnCalculation";
import {GroupHeader} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/GroupHeader/GroupHeader";
import {
    HealthInsuranceEdit
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/SelfEmploymentStatements/HealthInsuranceEdit/HealthInsuranceEdit";
import {
    SocialInsuranceEdit
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/SelfEmploymentStatements/SocialInsuranceEdit/SocialInsuranceEdit";

import './selfEmploymentStatements.scss';

export type SelfEmploymentStatementsProps = {
    isSelfEmployed: boolean;
    calculation: TaxReturnCalculation
}

export const SelfEmploymentStatements: FC<SelfEmploymentStatementsProps> = ({isSelfEmployed, calculation}) => {
    const {t} = useTranslation();

    const socialInsuranceSummaryRequired = useWatch<TaxReturn>({name: 'socialInsurance.summaryRequired'}) as boolean;

    if (!isSelfEmployed) {
        return null;
    }

    return <div className="tax-return-edit-section__group self-employment-statements">
        <p className="self-employment-statements__info">{t('SelfEmploymentStatements.info')}</p>
        <GroupHeader name="statementsHeadline" />
        <SocialInsuranceEdit calculation={calculation} />
        {socialInsuranceSummaryRequired && <HealthInsuranceEdit calculation={calculation} />}
    </div>

}
