import {FC} from "react";
import {FieldPath, useWatch} from "react-hook-form";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {CheckboxField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxField/CheckboxField";
import {useFormFieldCb} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormFieldCb";
import {TaxReturnWithExtraFormFields} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormFieldsResponse";

import './monthsSelection.scss';

export type MonthsSelectionProps = {
    wholeMonthName: FieldPath<TaxReturn>;
    monthsName: Extract<FieldPath<TaxReturn>, 'socialInsurance.selfEmployedMonths'|`socialInsurance.${string}.months`>;
    wholeYearDisabled?: boolean;
    disabledMonths?: boolean[];
}

export const MonthsSelection: FC<MonthsSelectionProps> = ({wholeMonthName, monthsName, wholeYearDisabled, disabledMonths}) => {
    const wholeMonthChecked = useWatch<TaxReturn>({name: wholeMonthName}) as boolean;

    const formFieldCb = useFormFieldCb();

    return <div className="months-selection">
        <CheckboxField name={wholeMonthName} customFormField={formFieldCb('socialInsurance.wholeYear')} customDisabled={wholeYearDisabled} />
        {(wholeYearDisabled || !wholeMonthChecked) && <div className="months-selection__months">
            {[...Array(12)].map((_, index) => (
                <CheckboxField
                    name={`${monthsName}.${index}`}
                    customFormField={formFieldCb(`socialInsurance.months.${index}` as FieldPath<TaxReturnWithExtraFormFields>, false)}
                    key={index}
                    customDisabled={disabledMonths && !disabledMonths[index]}
                />
            ))}
        </div>}
    </div>

}
