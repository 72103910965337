import {useCallback} from "react";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {TaxReturnValidationTarget} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnValidationTarget";

export const useValidateTaxReturnApi = (taxReturnId: string) => {
    const api = useApi<TaxReturnValidationTarget[]>();

    return useCallback(async () => {
        return await api(
            {url: `/tax-returns/${taxReturnId}/validate/`, method: HttpMethod.POST},
        );
    }, [api, taxReturnId]);
}
