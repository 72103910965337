import classNames from "classnames";
import {FC} from "react";

import {TaxReturnTitle} from "@app/common/components/TaxReturnTitle/TaxReturnTitle";
import {ValidatedTaxReturn} from "@app/common/model/ValidatedTaxReturn";
import {TaxReturnState} from "@app/Dashboard/DashboardTaxReturn/TaxReturnState/TaxReturnState";

import './dashboardTaxReturn.scss';

export type DashboardTaxReturnProps = {
    validatedTaxReturn: ValidatedTaxReturn;
}

export const DashboardTaxReturn: FC<DashboardTaxReturnProps> = ({validatedTaxReturn}) => {
    return <div className={classNames('dashboard-tax-return', `dashboard-tax-return--${validatedTaxReturn.taxReturn.state}`)}>
        <TaxReturnTitle taxReturn={validatedTaxReturn.taxReturn} />
        <TaxReturnState validatedTaxReturn={validatedTaxReturn} />
    </div>

}
