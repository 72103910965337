import {FC} from "react";

import {countries} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/CountrySelectField/coutries";
import {SelectField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/SelectField";
import {FieldComponentProps} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FieldComponent";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";

export type CountrySelectFieldProps = FieldComponentProps & {
    customFormField?: FormField;
}

export const CountrySelectField: FC<CountrySelectFieldProps> = ({name, customFormField}) => {
    return <SelectField name={name} options={countries} customFormField={customFormField} />
};
