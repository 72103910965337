import {FC} from "react";

import {TaxReturnTitle} from "@app/common/components/TaxReturnTitle/TaxReturnTitle";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";

export type TaxReturnDownloadPageProps = {
    taxReturn: TaxReturn;
}

export const TaxReturnDownloadPage: FC<TaxReturnDownloadPageProps> = ({taxReturn}) => {
    return <div>
        <TaxReturnTitle taxReturn={taxReturn} />
        <p>DOWNLOAD</p>
    </div>
}

export const TaxReturnDownloadPageTitle = 'TaxReturnDownloadPage.title';

export const TaxReturnDownloadPagePath = '/tax-return/:id/download/';
