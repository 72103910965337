import {FC} from "react";
import {useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {SelfEmploymentExpensesType} from "@app/common/model/TaxReturn/SelfEmploymentExpensesType";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {
    SelfEmploymentStatsEditItem
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/SelfEmploymentEdit/SelfEmploymentStatsEdit/SeflEmploymentStatsEditItem/SelfEmploymentStatsEditItem";

import './selfEmploymentStatsEdit.scss';

export const SelfEmploymentStatsEdit: FC = () => {
    const {t} = useTranslation();
    const expensesType = useWatch<TaxReturn, 'selfEmployment.expensesType'>({name: 'selfEmployment.expensesType'});

    if (expensesType !== SelfEmploymentExpensesType.REAL) {
        return null;
    }

    return <div className="self-employment-stats-edit">
        <p>{t('SelfEmploymentStatsEdit.title')}</p>
        <SelfEmploymentStatsEditItem name="fixedAsset" />
        <SelfEmploymentStatsEditItem name="moneyInCash" optional={true} />
        <SelfEmploymentStatsEditItem name="moneyOnBankAccount" optional={true} />
        <SelfEmploymentStatsEditItem name="stocks" />
        <SelfEmploymentStatsEditItem name="receivables" />
        <SelfEmploymentStatsEditItem name="otherItems" optional={true} />
        <SelfEmploymentStatsEditItem name="payables" />
        <SelfEmploymentStatsEditItem name="reserves" />
        <FieldsRow>
            <div />
            <AmountField name="selfEmployment.selfEmploymentStats.wages" />
        </FieldsRow>
    </div>
}
