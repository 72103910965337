import classNames from "classnames";
import {DetailedHTMLProps, forwardRef, Fragment, InputHTMLAttributes} from "react";

import {FCWithChildren} from "@app/common/model/FCWithChildren";

import './textInput.scss';

export type TextInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    error?: string;
};

export const TextInput: FCWithChildren<TextInputProps> = forwardRef(({children, className, error, type, ...inputProps}, ref) => {
    return <Fragment>
        <div className={classNames('text-input', error ? 'text-input--with-error' : null, className)}>
            <label>
                <div className="text-input__label">{children}</div>
                <input {...inputProps} type={type || 'text'} ref={ref}/>
            </label>
        </div>
        {error && <div className="text-input__error">{error}</div>}
    </Fragment>
})

TextInput.displayName = 'TextInput';
