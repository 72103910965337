import {useCallback} from "react";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {TaxReturnCalculation} from "@app/common/model/TaxReturn/TaxReturnCalculation";

export const useRecalculateTaxReturnApi = () => {
    const api = useApi<TaxReturnCalculation>();

    return useCallback((taxReturnId: string) => {
        return api(
            {url: `/tax-returns/${taxReturnId}/recalculate`, method: HttpMethod.POST},
        );
    }, [api]);
}
