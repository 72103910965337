import {FC, useMemo} from "react";

import {SelectOption} from "@app/common/components/form/Select/Select";
import {SelectField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/SelectField";
import {FieldComponentProps} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FieldComponent";

export const NumberOfMonthsField: FC<FieldComponentProps> = ({name, optional}) => {
    const options: SelectOption[] = useMemo(() => [...Array(12)].map<SelectOption>((_, index) => {
        const value = (index + 1).toString(10);
        return {value, label: value};
    }), []);

    return <SelectField
        name={name}
        options={options}
        optional={optional}
    />
};
