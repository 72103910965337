import {faCheck, faPen, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {Fragment, MouseEventHandler, useCallback, useEffect, useState} from "react";
import {FormProvider} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import {Submit} from "@app/common/components/form/Submit/Submit";
import {Loading} from "@app/common/components/Loading/Loading";
import {TaxReturnStepTitle} from "@app/common/components/TaxReturnStepTitle/TaxReturnStepTitle";
import {useStateProp} from "@app/common/hooks/useStateProp";
import {FCWithChildren} from "@app/common/model/FCWithChildren";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {TaxReturnState} from "@app/common/model/TaxReturn/TaxReturnState";
import {ValidatedTaxReturn} from "@app/common/model/ValidatedTaxReturn";
import {EditingContext,} from "@app/TaxReturnDetail/TaxReturnEdit/common/contexts/EditingContext";
import {useFocusFirstInput} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFocusFirstInput";
import {usePatchForm} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/usePatchForm";
import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {getNextStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/utils/getNextStep";
import {taxReturnSectionTo} from "@app/TaxReturnDetail/TaxReturnEdit/common/utils/taxReturnSectionTo";
import {validationTargetForStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/utils/validationTargetForStep";

import './editSection.scss';

export type EditSectionProps = {
    validatedTaxReturn: ValidatedTaxReturn;
    defaultOpened: boolean;
    defaultValidate?: boolean;
    preview?: boolean;
    step: TaxReturnStep;
}

export const EditSection: FCWithChildren<EditSectionProps> = ({
    children,
    validatedTaxReturn,
    defaultOpened,
    defaultValidate = false,
    preview = false,
    step,
}) => {
    const {t} = useTranslation();

    const isNew = !preview && validatedTaxReturn.taxReturn.state === TaxReturnState.CREATED;
    const nextStep = isNew ? getNextStep(step) : null;

    const {form, onSubmit, submitting} = usePatchForm(
        validatedTaxReturn.taxReturn,
        step,
        defaultValidate,
    );

    const [opened, setOpened] = useStateProp<boolean>(defaultOpened || preview);
    const [editing, setEditing] = useState<boolean>(isNew || defaultValidate);

    const navigate = useNavigate();
    const submit = useCallback(async (submittedTaxReturn: TaxReturn) => {
        const isValid = await onSubmit(submittedTaxReturn);
        if (isValid && nextStep) {
            setOpened(false);
            navigate(taxReturnSectionTo(submittedTaxReturn.id, nextStep, false));
        }
    }, [navigate, nextStep, onSubmit, setOpened]);

    const onEdit = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
        event.stopPropagation();
        setEditing(true);
        setOpened(true);
    }, [setOpened]);

    const onDone = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
        event.stopPropagation();
        setEditing(false);
    }, []);

    useEffect(() => {
        if (preview) {
            setEditing(false);
        }
    }, [preview]);

    const {containerRef, focus} = useFocusFirstInput();
    useEffect(() => {
        if (editing && opened) {
            focus();
        }
    }, [editing, opened, focus]);

    const hasErrors = validatedTaxReturn.validation.includes(validationTargetForStep(step)) || !form.formState.isValid;
    console.log('render', step, form);

    return <div className="tax-return-edit-section">
        <Loading active={submitting}>
            <h3
                onClick={() => setOpened(!opened)}
                className={classNames('tax-return-edit-section__header', `tax-return-edit-section__header--${hasErrors ? 'error' : 'completed'}`)}
            >
                <div className="tax-return-edit-section__header-title">
                    <FontAwesomeIcon icon={hasErrors ? faXmark : faCheck}/>
                    <TaxReturnStepTitle step={step}/>
                </div>

                {!isNew && <Fragment>
                    {!editing && <a onClick={onEdit} className="tax-return-edit-section__header-edit">
                        <FontAwesomeIcon icon={faPen}/>
                        {t('TaxReturnEditPage.actions.edit')}
                    </a>}

                    {editing && <a onClick={onDone} className="tax-return-edit-section__header-edit">
                        <FontAwesomeIcon icon={faCheck}/>
                        {t('TaxReturnEditPage.actions.done')}
                    </a>}
                </Fragment>}
            </h3>
        </Loading>
        <EditingContext.Provider value={editing}>
            <FormProvider {...form}>
                {opened && <div className="tax-return-edit-section__content" ref={containerRef}>
                    <form onSubmit={form.handleSubmit(submit)}>
                        {children}

                        {nextStep && <Submit variant="primary" className="tax-return-edit-section__submit">
                            {t('TaxReturnEditPage.actions.nextStep')}
                        </Submit>}
                    </form>
                </div>}
            </FormProvider>
        </EditingContext.Provider>
    </div>
}
