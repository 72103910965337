import {FC} from "react";
import {FieldPath} from "react-hook-form";

import {HelpIcon} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/HelpIcon/HelpIcon";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";
import {TaxReturnWithExtraFormFields} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormFieldsResponse";

import './labelWithHelp.scss';

export type LabelWithHelpProps = {
    name: FieldPath<TaxReturnWithExtraFormFields>;
}

export const LabelWithHelp: FC<LabelWithHelpProps> = ({name}) => {
    const formField = useFormField(name);

    return <div className="label-with-help">
        <div>{formField.label}</div>
        <HelpIcon formField={formField} />
    </div>

}
