import {FC} from "react";

import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {
    TaxOfficesEdit
} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/TaxOfficeGroup/TaxOfficesEdit/TaxOfficesEdit";

export const TaxOfficeGroup: FC = () => {
    return <div className="tax-return-edit-section__group">
        <TaxOfficesEdit />
        <FieldsRow>
            <TextField name="taxNumber" />
            <TextField name="birthNumber" />
        </FieldsRow>
    </div>
}
