import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {TaxReturnValidationTarget} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnValidationTarget";

export const validationTargetForStep = (step: TaxReturnStep): TaxReturnValidationTarget => {
    switch (step) {
        case TaxReturnStep.INCOME: return TaxReturnValidationTarget.INCOME;
        case TaxReturnStep.PERSONAL_DATA: return TaxReturnValidationTarget.PERSONAL_DATA;
        case TaxReturnStep.DEDUCTIBLES: return TaxReturnValidationTarget.DEDUCTIBLES;
        case TaxReturnStep.TAX: return TaxReturnValidationTarget.TAX;
        case TaxReturnStep.PAY: return TaxReturnValidationTarget.ALL;
    }
}

export const stepForValidationTarget = (target: TaxReturnValidationTarget): TaxReturnStep => {
    switch (target) {
        case TaxReturnValidationTarget.INCOME: return TaxReturnStep.INCOME;
        case TaxReturnValidationTarget.PERSONAL_DATA: return TaxReturnStep.PERSONAL_DATA;
        case TaxReturnValidationTarget.DEDUCTIBLES: return TaxReturnStep.DEDUCTIBLES;
        case TaxReturnValidationTarget.TAX: return TaxReturnStep.TAX;
        case TaxReturnValidationTarget.ALL: return TaxReturnStep.PAY;
    }
}
