import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";

import {Button} from "@app/common/components/Button/Button";
import {useTitle} from "@app/common/layouts/hooks/useTitle";
import {useValidatedTaxReturns} from "@app/common/layouts/SecuredLayout/hooks/useValidatedTaxReturns";
import {CreateTaxReturnForm} from "@app/Dashboard/CreateTaxReturnForm/CreateTaxReturnForm";
import {DashboardTaxReturn} from "@app/Dashboard/DashboardTaxReturn/DashboardTaxReturn";

import './dashboardPage.scss';

export const DashboardPage: FC = () => {
    const {t} = useTranslation();
    useTitle(t(DashboardPageTitle));

    const {validatedTaxReturns} = useValidatedTaxReturns();

    const [createModalOpened, setCreateModalOpened] = useState<boolean>(validatedTaxReturns.length === 0);

    return <div className="dashboard-page">
        <h1>{t(DashboardPageTitle)}</h1>
        <Button onClick={() => setCreateModalOpened(true)} className="dashboard-page__create-new-tax-return">
            <FontAwesomeIcon icon={faPlus} />{' '}
           {t('Dashboard.actions.createNewTaxReturn')}
        </Button>
        {validatedTaxReturns && <div>
            {validatedTaxReturns.map((validatedTaxReturn) => (<DashboardTaxReturn
                validatedTaxReturn={validatedTaxReturn}
                key={validatedTaxReturn.taxReturn.id}
            />))}
        </div>}
        <Modal
            isOpen={createModalOpened}
            onRequestClose={() => setCreateModalOpened(false)}
            className="dashboard-page__new-tax-return-modal-content"
            overlayClassName="dashboard-page__new-tax-return-modal-overlay"
            ariaHideApp={false}
        >
            <CreateTaxReturnForm />
            <Button onClick={() => setCreateModalOpened(false)} variant="secondary">
                {t('Dashboard.actions.cancel')}
            </Button>
        </Modal>
    </div>
}

export const DashboardPageTitle = 'Dashboard.title';

export const DashboardPagePath = '/';
