import {EditSection} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/EditSection/EditSection";
import {SectionComponent} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/SectionComponent";
import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {AbroadIncomes} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/AbroadIncomes/AbroadIncomes";
import {
    CapitalAssetsIncomeEdit
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/CapitalAssetsIncomeEdit/CapitalAssetsIncomeEdit";
import {Employments} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/Employments/Employments";
import {LeaseIncomeEdit} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/LeaseIncomeEdit/LeaseIncomeEdit";
import {OtherIncomes} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/OtherIncomes/OtherIncomes";
import {SelfEmploymentEdit} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/SelfEmploymentEdit/SelfEmploymentEdit";

export const IncomeEdit: SectionComponent = ({opened, validatedTaxReturn, validate, preview}) => {
    return <EditSection
        validatedTaxReturn={validatedTaxReturn}
        step={TaxReturnStep.INCOME}
        defaultValidate={validate}
        defaultOpened={opened}
        preview={preview}
    >
        <Employments />
        <SelfEmploymentEdit />
        <LeaseIncomeEdit />
        <CapitalAssetsIncomeEdit />
        <AbroadIncomes />
        <OtherIncomes />
    </EditSection>
}
