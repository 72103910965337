import {FC} from "react";
import {useTranslation} from "react-i18next";

import {Button} from "@app/common/components/Button/Button";
import {CheckboxField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxField/CheckboxField";
import {
    NumberOfMonthsField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumberOfMonthsField/NumberOfMonthsField";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

import './childEdit.scss';

export type ChildEditProps = {
    index: number;
    onRemove?: (index: number) => void;
}

export const ChildEdit: FC<ChildEditProps> = ({index, onRemove}) => {
    const {t} = useTranslation();

    return <div className="child-edit">
        <FieldsRow>
            <TextField name={`deductibles.children.${index}.firstName`} />
            <TextField name={`deductibles.children.${index}.lastName`} />
            <TextField name={`deductibles.children.${index}.birthNumber`} />
        </FieldsRow>
        <FieldsRow>
            <NumberOfMonthsField name={`deductibles.children.${index}.numberOfMonths`} />
            <CheckboxField name={`deductibles.children.${index}.ztpLicense`} />
            {onRemove && <div className="child-edit__remove">
                <Button onClick={() => onRemove(index)} variant="secondary">
                    {t('ChildEdit.actions.remove')}
                </Button>
            </div>}
        </FieldsRow>
    </div>
}
