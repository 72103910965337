import {FC} from "react";
import {useWatch} from "react-hook-form";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {TaxReturnState} from "@app/common/model/TaxReturn/TaxReturnState";
import {DateField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/DateField/DateField";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {GroupHeader} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/GroupHeader/GroupHeader";
import {ReadOnlyFlag} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/ReadOnlyFlag/ReadOnlyFlag";

export const PersonalDataGroup: FC = () => {
    const taxReturnState = useWatch<TaxReturn>({name: 'state'}) as TaxReturnState;
    const isPaid = taxReturnState ===  TaxReturnState.PAID;

    return <div className="tax-return-edit-section__group">
        <GroupHeader name="sections.personalData" />
        <FieldsRow>
            <div>
                <TextField name="personalData.firstName" customDisabled={isPaid} />
                <ReadOnlyFlag name="personalData.firstName" />
            </div>
            <div>
                <TextField name="personalData.lastName" customDisabled={isPaid} />
                <ReadOnlyFlag name="personalData.lastName" />
            </div>
        </FieldsRow>
        <FieldsRow>
            <TextField name="personalData.title" />
            <TextField name="personalData.birthLastName" optional={true} />
        </FieldsRow>
        <FieldsRow>
            <TextField name="personalData.phone" />
            <TextField name="personalData.email" />
        </FieldsRow>
        <FieldsRow>
            <TextField name="personalData.nationality" />
            <DateField name="socialInsurance.birthDate" />
        </FieldsRow>
    </div>
}
