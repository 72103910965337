import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC, Fragment, useCallback, useEffect} from "react";
import {useFieldArray} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {Button} from "@app/common/components/Button/Button";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";
import {
    OtherActivityEdit
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/SelfEmploymentEdit/OtherActivities/OtherActivityEdit/OtherActivityEdit";

export const OtherActivities: FC = () => {
    const {t} = useTranslation();
    const editing = useEditingContext();

    const {fields, append, remove} = useFieldArray<TaxReturn, 'selfEmployment.otherActivities'>(
        {name: 'selfEmployment.otherActivities'}
    );

    const onEmploymentAdd = useCallback(() => {
        append({});
    }, [append]);

    useEffect(() => {
        if (fields.length === 0) {
            append({});
        }
    }, [append, fields.length]);

    return <Fragment>
        {(editing || fields.length > 0) && <p>{t('OtherActivities.title')}</p>}
        {fields.map((field, index) => (
            <OtherActivityEdit index={index} onRemove={editing ? remove : undefined} key={field.id} />
        ))}
        {editing && fields.length < 3 && <Button onClick={onEmploymentAdd} variant="secondary">
            <FontAwesomeIcon icon={faPlus} />{' '}
            {t('OtherActivities.actions.add')}
        </Button>}
    </Fragment>
}
