import {FC, useCallback, useEffect} from "react";
import {FieldPath, get, GlobalError, useFormContext, useWatch} from "react-hook-form";

import {Checkbox} from "@app/common/components/form/Checkbox/Checkbox";
import {AccountType} from "@app/common/model/TaxReturn/AccountType";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";

export type AccountTypeRadioProps = {
    name: Extract<FieldPath<TaxReturn>, `${string}.accountType`>
}

export const AccountTypeRadio: FC<AccountTypeRadioProps> = ({name}) => {
    const editing = useEditingContext();
    const {register, setValue, formState} = useFormContext<TaxReturn>();

    useEffect(() => {
        register(name);
    }, [name, register]);

    const onDomesticSelect = useCallback(() => {
        setValue(name, AccountType.DOMESTIC);
    }, [name, setValue]);

    const onForeignSelect = useCallback(() => {
        setValue(name, AccountType.FOREIGN);
    }, [name, setValue]);

    const value = useWatch<TaxReturn, Extract<FieldPath<TaxReturn>, `${string}.accountType`>>({name});
    useEffect(() => {
        if (!value) {
            setValue(name, AccountType.DOMESTIC);
        }
    }, [name, setValue, value]);

    const domesticFormField = useFormField('account.accountType.domestic');
    const foreignFormField = useFormField('account.accountType.foreign');

    const error = get(formState.errors, name) as GlobalError|undefined;

    return <FieldsRow className="account-type-radio">
        <div className="checkbox-field">
            <div className="checkbox-field__title">
                <Checkbox
                    onChange={onDomesticSelect}
                    checked={value === AccountType.DOMESTIC}
                    disabled={!editing}
                    error={error?.message}
                >
                    {domesticFormField.label}
                </Checkbox>
            </div>
        </div>
        <div className="checkbox-field">
            <div className="checkbox-field__title">
                <Checkbox
                    onChange={onForeignSelect}
                    checked={value === AccountType.FOREIGN}
                    disabled={!editing}
                >
                    {foreignFormField.label}
                </Checkbox>
            </div>
        </div>

    </FieldsRow>


}
