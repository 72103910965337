import {FC} from "react";

import {DateField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/DateField/DateField";
import {
    NumberOfMonthsField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumberOfMonthsField/NumberOfMonthsField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {LabelWithHelp} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/LabelWithHelp/LabelWithHelp";

export const TradeLicenseEdit: FC = () => {
    return <div className="trade-license-edit">
        <LabelWithHelp name="selfEmployment.about" />
        <FieldsRow>
            <DateField name="selfEmployment.tradeLicense.commencementDate" optional={true} />
            <NumberOfMonthsField name="selfEmployment.tradeLicense.numberOfActivityMonths" />
        </FieldsRow>
        <FieldsRow>
            <DateField name="selfEmployment.tradeLicense.interruptionDate" optional={true} />
            <DateField name="selfEmployment.tradeLicense.terminationDate" optional={true} />
            <DateField name="selfEmployment.tradeLicense.renewalDate" optional={true} />
        </FieldsRow>
    </div>
}
