import {FC} from "react";

import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {AddressEdit} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/AddressEdit/AddressEdit";

export const LastDayAddress: FC = () => {
    return <CheckboxGroup name="sections.lastDayAddress">
        <AddressEdit name="lastDayAddress" />
    </CheckboxGroup>
}
