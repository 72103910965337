import {Fragment} from "react";

import {EditSection} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/EditSection/EditSection";
import {SectionComponent} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/SectionComponent";
import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {OverPaymentEdit} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/OverPaymentEdit/OverPaymentEdit";
import {
    SelfEmploymentStatements
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/SelfEmploymentStatements/SelfEmploymentStatements";
import {TaxInfo} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/TaxInfo/TaxInfo";

export const TaxEdit: SectionComponent = ({opened, validatedTaxReturn, validate, preview}) => {
    const {calculation} = validatedTaxReturn.taxReturn;

    return <EditSection
        validatedTaxReturn={validatedTaxReturn}
        step={TaxReturnStep.TAX}
        defaultValidate={validate}
        defaultOpened={opened}
        preview={preview}
    >
        {calculation && <Fragment>
            <TaxInfo validatedTaxReturn={validatedTaxReturn} calculation={calculation} />
            <OverPaymentEdit calculation={calculation} />
            <SelfEmploymentStatements isSelfEmployed={validatedTaxReturn.taxReturn.sections.selfEmployment} calculation={calculation} />
        </Fragment>}
    </EditSection>

}
