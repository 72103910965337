import {FC, useMemo} from "react";
import {FieldPath} from "react-hook-form";

import {SelectOption} from "@app/common/components/form/Select/Select";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {SelectField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/SelectField";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";

export type ExpensesTypeSelectProps = {
    name: FieldPath<TaxReturn>
    allowReal?: boolean;
    allowLump?: boolean;
}

export const ExpensesTypeSelect: FC<ExpensesTypeSelectProps> = ({name, allowReal = true, allowLump = true}) => {
    const realFormField = useFormField('selfEmployment.expensesTypes.real');
    const lump60FormField = useFormField('selfEmployment.expensesTypes.lump_60');
    const lump80FormField = useFormField('selfEmployment.expensesTypes.lump_80');
    const lump40FormField = useFormField('selfEmployment.expensesTypes.lump_40');
    const disabledFormField = useFormField('selfEmployment.otherActivities.0.disabledExpensesType');

    const options: SelectOption[] = useMemo(() => {
        const result: SelectOption[] = [];
        if (allowReal) {
            result.push({value: 'real', label: realFormField.label});
        }
        if (allowLump) {
            result.push({value: 'lump_60', label: lump60FormField.label});
            result.push({value: 'lump_80', label: lump80FormField.label});
            result.push({value: 'lump_40', label: lump40FormField.label});
        }

        return result;
    }, [allowLump, allowReal, lump40FormField.label, lump60FormField.label, lump80FormField.label, realFormField.label]);

    return <SelectField
        name={name}
        options={options}
        customPlaceholder={!allowReal && !allowLump ? disabledFormField.label : undefined}
    />

}
