import {FC, Fragment} from "react";

import {CheckboxField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxField/CheckboxField";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

export const DataBox: FC = () => {
    return <Fragment>
        <CheckboxGroup name="hasDatabox">
            <FieldsRow>
                <TextField name="databoxId" />
            </FieldsRow>
        </CheckboxGroup>
        <CheckboxField name="submitViaDatabox" />
    </Fragment>
}
