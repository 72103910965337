import {FC, useCallback, useRef, useState} from "react";
import {generatePath, Link} from "react-router-dom";

import {TaxReturnTitle} from "@app/common/components/TaxReturnTitle/TaxReturnTitle";
import {useOutsideClick} from "@app/common/hooks/useOutsideClick";
import {LanguageSelect} from "@app/common/layouts/components/LanguageSelect/LanguageSelect";
import {HamburgerIcon} from "@app/common/layouts/SecuredLayout/components/Header/MainMenu/HamburgerIcon/HamburgerIcon";
import {LogoutLink} from "@app/common/layouts/SecuredLayout/components/Header/MainMenu/LogoutLink/LogoutLink";
import {useUser} from "@app/common/layouts/SecuredLayout/hooks/useUser";
import {useValidatedTaxReturns} from "@app/common/layouts/SecuredLayout/hooks/useValidatedTaxReturns";
import {TaxReturnDetailPagePath} from "@app/TaxReturnDetail/TaxReturnDetailLayout";
import {UserSettingsPagePath} from "@app/UserSettings/UserSettingsPage";

import './mainMenu.scss';

export const MainMenu: FC = () => {
    const [opened, setOpened] = useState<boolean>(false);
    const menuRef = useRef(null);

    const user = useUser();
    const {validatedTaxReturns} = useValidatedTaxReturns();

    const closeMenu = useCallback(() => {
        setOpened(false);
    }, [])

    useOutsideClick(closeMenu, menuRef);

    return <div className="main-menu" ref={menuRef}>
        <HamburgerIcon opened={opened} onClick={() => setOpened(!opened)} />

        {opened && <nav className="main-menu__nav">
            <ul>
                {validatedTaxReturns.map(({taxReturn}) => (<li key={taxReturn.id}>
                    <Link to={generatePath(TaxReturnDetailPagePath, {id: taxReturn.id})} onClick={closeMenu}>
                        <TaxReturnTitle taxReturn={taxReturn} inline={true} />
                    </Link>
                </li>))}
                <li>
                    <Link to={UserSettingsPagePath} onClick={closeMenu}>
                        {user.firstName} {user.lastName}
                    </Link>
                </li>
                <li><LogoutLink /></li>
                <li><LanguageSelect onChange={closeMenu} /></li>
            </ul>
        </nav>}
    </div>
}
