import {useContext} from "react";

import {UserContext} from "@app/common/layouts/SecuredLayout/contexts/UserContext";
import {User} from "@app/common/model/User";

export const useUser = (): User => {
    const user = useContext(UserContext);

    if (!user) {
        throw new Error('Trying to use an uninstantiated UserContext');
    }

    return user;
}
