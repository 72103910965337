import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {FormFieldsResponse} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormFieldsResponse";

export const useFormFieldsApi = () => {
    const {i18n} = useTranslation();

    const api = useApi<FormFieldsResponse>();

    return useCallback(() => {
        return api(
            {url: `/form-fields/${i18n.language.split('-').shift()}`, method: HttpMethod.GET},
        );
    }, [api, i18n.language]);
}
