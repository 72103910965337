import {FC} from "react";
import {useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {Button} from "@app/common/components/Button/Button";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

import './employmentEdit.scss';

export type EmploymentEditProps = {
    index: number;
    onRemove?: (index: number) => void;
}

export const EmploymentEdit: FC<EmploymentEditProps> = ({index, onRemove}) => {
    const {t} = useTranslation();

    const year = useWatch<TaxReturn>({name: 'year'}) as number;

    return <div className="employment-edit">
        <FieldsRow>
            <AmountField name={`employments.${index}.grossIncome`} />
            {year < 2021 && <AmountField name={`employments.${index}.paidInsurance`} optional={true} />}
            <AmountField name={`employments.${index}.paidAdvances`} optional={true} />
        </FieldsRow>
        <FieldsRow>
            <AmountField name={`employments.${index}.witholdingTax`} optional={true} />
            <AmountField name={`employments.${index}.taxBonus`} />
            {onRemove && <div className="employment-edit__remove">
                <Button onClick={() => onRemove(index)} variant="secondary">
                    {t('Employments.actions.remove')}
                </Button>
            </div>}
        </FieldsRow>
    </div>
}
