import {useCallback} from "react";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {User} from "@app/common/model/User";

export const useUserInfoApi = () => {
    const api = useApi<User>();

    return useCallback(() => {
        return api({url: '/persons/me', method: HttpMethod.GET});
    }, [api]);
}
