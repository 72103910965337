import {useCallback} from "react";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {User} from "@app/common/model/User";
import {ValidatedTaxReturn} from "@app/common/model/ValidatedTaxReturn";

export const useValidatedTaxReturnsApi = () => {
    const api = useApi<ValidatedTaxReturn[]>();

    return useCallback((user: User) => {
        return api(
            {url: `/persons/${user.id}/validated-tax-returns`, method: HttpMethod.GET},
            undefined,
            [{name: 'order', value: 'year DESC, updatedAt DESC'}],
        );
    }, [api]);
}
