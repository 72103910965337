import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {TaxReturnCalculation} from "@app/common/model/TaxReturn/TaxReturnCalculation";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {
    AccountEdit
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/SelfEmploymentStatements/AccountEdit/AccountEdit";

export type SocialInsuranceInfoProps = {
    calculation: TaxReturnCalculation;
}

export const SocialInsuranceInfo: FC<SocialInsuranceInfoProps> = ({calculation}) => {
    const {t} = useTranslation();

    if (!calculation.social) {
        return null;
    }

    return <Fragment>
        <div className="tax-return-edit-section__group tax-info">
            <div className="group-header">
                <p className="tax-info__tax-is">{t('SocialInsuranceInfo.socialIs', {amount: calculation.social })}</p>
                <p>{t('SocialInsuranceInfo.explanation')}</p>
            </div>
        </div>
        {calculation.social < 0 && <CheckboxGroup name="socialInsurance.overPayment.returnRequired" className="overpayment-edit">
            <AccountEdit accountPath="socialInsurance.overPayment.account" />
        </CheckboxGroup>}
    </Fragment>

}
