import {FC, Fragment, useState} from "react";

import {HelpIcon} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/HelpIcon/HelpIcon";
import {HelpLink} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/HelpLink/HelpLink";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";
import {BeforeYouStart} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/BeforeYouStart/BeforeYouStart";

import './selfEmploymentBeforeYouStart.scss'

export const SelfEmploymentBeforeYouStart: FC = () => {
    const [otherOpened, setOtherOpened] = useState<boolean>(false);

    const otherTitleFormField = useFormField('selfEmployment.other.title');

    return <BeforeYouStart>
        <HelpLink name="selfEmployment.before.trade_licence" />
        <HelpLink name="selfEmployment.before.registration_from_the_financial_office" />
        <HelpLink name="selfEmployment.before.registration_from_the_czech_social_security_office" />
        <HelpLink name="selfEmployment.before.health_insurance_card_or_registration" />
        {!otherOpened && <div className="self-employment-before-you-start__other">
            <a onClick={() => setOtherOpened(true)}>{otherTitleFormField.label}</a>
            <HelpIcon formField={otherTitleFormField} />
        </div>}
        {otherOpened && <Fragment>
            <HelpLink name="selfEmployment.other.certificate_of_your_registration_number" />
            <HelpLink name="selfEmployment.other.summary_of_payments_to_social_security_office" />
            <HelpLink name="selfEmployment.other.summary_of_payments_to_health_insurance_office" />
        </Fragment>}
        <p>{otherTitleFormField.placeholder} <a href="mailto:info@czechtaxesonline.cz">info@czechtaxesonline.cz</a></p>
    </BeforeYouStart>
}
