import {EditSection} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/EditSection/EditSection";
import {SectionComponent} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/SectionComponent";
import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {
    CorrectiveOrSupplementaryTaxReturn
} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/CorrectiveOrSupplementaryTaxReturn/CorrectiveOrSupplementaryTaxReturn";
import {
    CzechResidencyAddress
} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/CzechResidencyAddress/CzechResidencyAddress";
import {DataBox} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/DataBox/DataBox";
import {LastDayAddress} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/LastDayAddress/LastDayAddress";
import {
    NonCzTaxResidentEdit
} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/NonCzTaxResidentEdit/NonCzTaxResidentEdit";
import {PermanentAddress} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/PermanentAddress/PermanentAddress";
import {
    PersonalDataGroup
} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/PersonalDataGroup/PersonalDataGroup";
import {TaxOfficeGroup} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/TaxOfficeGroup/TaxOfficeGroup";

export const PersonalDataEdit: SectionComponent = ({opened, validatedTaxReturn, validate, preview}) => {
    return <EditSection
        validatedTaxReturn={validatedTaxReturn}
        step={TaxReturnStep.PERSONAL_DATA}
        defaultValidate={validate}
        defaultOpened={opened}
        preview={preview}
    >
        <PersonalDataGroup />
        <TaxOfficeGroup />
        <PermanentAddress />
        <CzechResidencyAddress />
        <LastDayAddress />
        <DataBox />
        <CorrectiveOrSupplementaryTaxReturn />
        <NonCzTaxResidentEdit />
    </EditSection>
}
