export type ErrorResponse<ErrorsType = string[]> = {
    errors: ErrorsType;
    code?: number;
    message?: string;
}

export const isErrorResponse = <ErrorsType = string[]>(error: unknown): error is ErrorResponse<ErrorsType> => {
    if (error !== null && typeof error === 'object') {
        return 'errors' in error;
    }

    return false;
}
