import classNames from "classnames";

import {FCWithChildren} from "@app/common/model/FCWithChildren";

import './loading.scss';

export type LoadingProps = {
    active?: boolean;
    className?: string;
    emptyWhenActive?: boolean;
    light?: boolean;
    title?: string;
}

export const Loading: FCWithChildren<LoadingProps> = ({
    active,
    children,
    className,
    emptyWhenActive = false,
    light = false,
    title,
}) => {
    return <div className={classNames('loading', className, {'loading--active': active})} title={title}>
        {active && <div className="loading__overlay">
            <div className={classNames('loading__spinner', light && 'loading__spinner-light')} />
        </div>}
        {(!emptyWhenActive || !active) && children}
    </div>
}
