import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {generatePath} from "react-router-dom";

import {LinkButton} from "@app/common/components/Button/LinkButton/LinkButton";
import {TaxReturnState as TaxReturnStateEnum} from "@app/common/model/TaxReturn/TaxReturnState";
import {ValidatedTaxReturn} from "@app/common/model/ValidatedTaxReturn";
import {
    TaxReturnStateButton
} from "@app/Dashboard/DashboardTaxReturn/TaxReturnState/TaxReturnStateButton/TaxReturnStateButton";
import {TaxReturnDownloadPagePath} from "@app/TaxReturnDetail/TaxReturnDownload/TaxReturnDownloadPage";
import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {TaxReturnValidationTarget} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnValidationTarget";
import {TaxReturnEditPagePath} from "@app/TaxReturnDetail/TaxReturnEdit/TaxReturnEditPage";

import './taxReturnState.scss';

export type TaxReturnStateProps = {
    validatedTaxReturn: ValidatedTaxReturn;
}

export const TaxReturnState: FC<TaxReturnStateProps> = ({validatedTaxReturn}) => {
    const {t} = useTranslation();

    const hasErrors = validatedTaxReturn.validation.length !== 0;
    const isPaid = validatedTaxReturn.taxReturn.state === TaxReturnStateEnum.PAID;

    return <div className="tax-return-state">
        <p className="tax-return-state__title">{t(`TaxReturnState.title.${validatedTaxReturn.taxReturn.state}`)}</p>
        {!isPaid && <Fragment>
            {!hasErrors && <p className="tax-return-state__subtitle">{t('TaxReturnState.subtitle.readyToPay')}</p>}
            {hasErrors && <p className="tax-return-state__subtitle">{t('TaxReturnState.subtitle.pleaseFill')}</p>}
        </Fragment>}
        {isPaid && <Fragment>
            {!hasErrors && <p className="tax-return-state__subtitle">{t('TaxReturnState.subtitle.readyToFile')}</p>}
            {hasErrors && <p className="tax-return-state__subtitle">{t('TaxReturnState.subtitle.incomplete')}</p>}
        </Fragment>}
        <div className="tax-return-state__list">
            {hasErrors && <Fragment>
                <TaxReturnStateButton
                    completed={!validatedTaxReturn.validation.includes(TaxReturnValidationTarget.INCOME)}
                    step={TaxReturnStep.INCOME}
                    taxReturn={validatedTaxReturn.taxReturn}
                />
                <TaxReturnStateButton
                    completed={!validatedTaxReturn.validation.includes(TaxReturnValidationTarget.PERSONAL_DATA)}
                    step={TaxReturnStep.PERSONAL_DATA}
                    taxReturn={validatedTaxReturn.taxReturn}
                />
                <TaxReturnStateButton
                    completed={!validatedTaxReturn.validation.includes(TaxReturnValidationTarget.DEDUCTIBLES)}
                    step={TaxReturnStep.DEDUCTIBLES}
                    taxReturn={validatedTaxReturn.taxReturn}
                />
                <TaxReturnStateButton
                    completed={!validatedTaxReturn.validation.includes(TaxReturnValidationTarget.TAX)}
                    step={TaxReturnStep.TAX}
                    taxReturn={validatedTaxReturn.taxReturn}
                />
            </Fragment>}
            {!isPaid && !hasErrors && <LinkButton
                to={generatePath(TaxReturnEditPagePath, {id: validatedTaxReturn.taxReturn.id, step: TaxReturnStep.PAY})}
                variant="warning"
            >{t('TaxReturnState.actions.pay')}</LinkButton>}
            {isPaid && !hasErrors && <LinkButton
                to={generatePath(TaxReturnDownloadPagePath, {id: validatedTaxReturn.taxReturn.id})}
                variant="success"
            >{t('TaxReturnState.actions.download')}</LinkButton>}
        </div>
    </div>

}
