import {FC, Fragment} from "react";
import {useWatch} from "react-hook-form";

import {AccountType} from "@app/common/model/TaxReturn/AccountType";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {TaxReturnCalculation} from "@app/common/model/TaxReturn/TaxReturnCalculation";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {NumericField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/NumericField";
import {
    CountrySelectField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/CountrySelectField/CountrySelectField";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {useFormFieldCb} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormFieldCb";
import {
    AccountTypeRadio
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/OverPaymentEdit/AccountTypeRadio/AccountTypeRadio";
import {
    CurrencySelectField
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/OverPaymentEdit/CurrencySelectField/CurrencySelectField";

export type OverPaymentEditProps = {
    calculation: TaxReturnCalculation;
}

export const OverPaymentEdit: FC<OverPaymentEditProps> = ({calculation}) => {
    const formFieldCb = useFormFieldCb();

    const accountType = useWatch<TaxReturn, 'overPayment.account.accountType'>({name: 'overPayment.account.accountType'});

    if (!calculation.tax || calculation.tax >= 0) {
        return null;
    }

    return <CheckboxGroup name="overPayment.returnRequired" className="overpayment-edit">
        <AccountTypeRadio name="overPayment.account.accountType" />
        {accountType === AccountType.DOMESTIC && <Fragment>
            <FieldsRow>
                <NumericField
                    name="overPayment.account.domesticAccount.prefix"
                    customFormField={formFieldCb('account.domesticAccount.prefix')}
                    optional={true}
                />
                <NumericField
                    name="overPayment.account.domesticAccount.number"
                    customFormField={formFieldCb('account.domesticAccount.number')}
                />
                <NumericField
                    name="overPayment.account.domesticAccount.code"
                    customFormField={formFieldCb('account.domesticAccount.code')}
                />
            </FieldsRow>
            <FieldsRow>
                <TextField
                    name="overPayment.account.domesticAccount.bankName"
                    customFormField={formFieldCb('account.domesticAccount.bankName')}
                />
                <TextField
                    name="overPayment.account.domesticAccount.accountHolder"
                    customFormField={formFieldCb('account.domesticAccount.accountHolder')}
                />
                <CurrencySelectField
                    name="overPayment.account.domesticAccount.currency"
                    customFormField={formFieldCb('account.domesticAccount.currency')}
                />
            </FieldsRow>
        </Fragment>}
        {accountType === AccountType.FOREIGN && <Fragment>
            <FieldsRow>
                <TextField
                    name="overPayment.account.foreignAccount.iban"
                    customFormField={formFieldCb('account.foreignAccount.iban')}
                />
                <TextField
                    name="overPayment.account.foreignAccount.swift"
                    customFormField={formFieldCb('account.foreignAccount.swift')}
                />
                <CurrencySelectField
                    name="overPayment.account.foreignAccount.currency"
                    customFormField={formFieldCb('account.foreignAccount.currency')}
                />
            </FieldsRow>
            <FieldsRow>
                <TextField
                    name="overPayment.account.foreignAccount.bankName"
                    customFormField={formFieldCb('account.foreignAccount.bankName')}
                />
                <TextField
                    name="overPayment.account.foreignAccount.bankAddress.street"
                    customFormField={formFieldCb('account.foreignAccount.bankAddress.street')}
                />
                <TextField
                    name="overPayment.account.foreignAccount.bankAddress.city"
                    customFormField={formFieldCb('account.foreignAccount.bankAddress.city')}
                />
            </FieldsRow>
            <FieldsRow>
                <TextField
                    name="overPayment.account.foreignAccount.bankAddress.zipCode"
                    customFormField={formFieldCb('account.foreignAccount.bankAddress.zipCode')}
                />
                <CountrySelectField
                    name="overPayment.account.foreignAccount.bankAddress.country"
                    customFormField={formFieldCb('account.foreignAccount.bankAddress.country')}
                />
            </FieldsRow>
            <FieldsRow>
                <TextField
                    name="overPayment.account.foreignAccount.accountHolderNameAndAddress"
                    customFormField={formFieldCb('account.foreignAccount.accountHolderNameAndAddress')}
                />
                <TextField
                    name="overPayment.account.foreignAccount.accountHolderAddress.street"
                    customFormField={formFieldCb('account.foreignAccount.accountHolderAddress.street')}
                />
                <TextField
                    name="overPayment.account.foreignAccount.accountHolderAddress.city"
                    customFormField={formFieldCb('account.foreignAccount.accountHolderAddress.city')}
                />
            </FieldsRow>
            <FieldsRow>
                <TextField
                    name="overPayment.account.foreignAccount.accountHolderAddress.zipCode"
                    customFormField={formFieldCb('account.foreignAccount.accountHolderAddress.zipCode')}
                />
                <CountrySelectField
                    name="overPayment.account.foreignAccount.accountHolderAddress.country"
                    customFormField={formFieldCb('account.foreignAccount.accountHolderAddress.country')}
                />
            </FieldsRow>
        </Fragment>}
    </CheckboxGroup>
}
