import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC, Fragment, useCallback, useEffect} from "react";
import {useFieldArray, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {Button} from "@app/common/components/Button/Button";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {CheckboxField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxField/CheckboxField";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";
import {ChildEdit} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/Children/ChildEdit/ChildEdit";

export const Children: FC = () => {
    const {t} = useTranslation();
    const editing = useEditingContext();

    const {fields, append, remove} = useFieldArray<TaxReturn, 'deductibles.children'>({name: 'deductibles.children'});
    const secondParentClaimSelected = useWatch<TaxReturn, 'sections.secondParentClaim'>({name: 'sections.secondParentClaim'});

    const onChildAdd = useCallback(() => {
        append({ztpLicense: false});
    }, [append]);

    useEffect(() => {
        if (fields.length === 0) {
            append({ztpLicense: false});
        }
    }, [append, fields.length]);

    return <CheckboxGroup name="sections.children">
        <CheckboxField name="sections.secondParentClaim" />
        {secondParentClaimSelected && <Fragment>
            {fields.map((field, index) => (
                <ChildEdit index={index} onRemove={editing && fields.length > 1 ? remove : undefined} key={field.id} />
            ))}
            {editing && <Button onClick={onChildAdd} variant="secondary">
                <FontAwesomeIcon icon={faPlus} />{' '}
                {t('ChildEdit.actions.add')}
            </Button>}
        </Fragment>}
    </CheckboxGroup>
}
