import {EditSection} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/EditSection/EditSection";
import {SectionComponent} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/SectionComponent";
import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {Children} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/Children/Children";
import {Donation} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/Donation/Donation";
import {Kindergarten} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/Kindergarten/Kindergarten";
import {LifeInsurance} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/LifeInsurance/LifeInsurance";
import {
    LongTermCareInsurance
} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/LongTermCareInsurance/LongTermCareInsurance";
import {
    LongTermInvestmentProduct
} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/LongTermInvestmentProduct/LongTermInvestmentProduct";
import {Marriage} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/Marriage/Marriage";
import {Mortgage} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/Mortgage/Mortgage";
import {
    NoDeductiblesMessage
} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/NoDeductiblesMessage/NoDeductiblesMessage";
import {PaidAdvances} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/PaidAdvances/PaidAdvances";
import {PensionInsurance} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/PensionInsurance/PensionInsurance";
import {Student} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/Student/Student";

export const DeductiblesEdit: SectionComponent = ({opened, validatedTaxReturn, validate, preview}) => {
    return <EditSection
        validatedTaxReturn={validatedTaxReturn}
        step={TaxReturnStep.DEDUCTIBLES}
        defaultValidate={validate}
        defaultOpened={opened}
        preview={preview}
    >
        <NoDeductiblesMessage preview={preview} />
        <Donation />
        <Mortgage />
        <PensionInsurance />
        <LifeInsurance />
        <LongTermCareInsurance />
        <LongTermInvestmentProduct />
        <Marriage />
        <Children />
        <Kindergarten />
        <Student />
        <PaidAdvances />
    </EditSection>
}
