import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {DetailedHTMLProps, forwardRef, InputHTMLAttributes} from "react";

import {FCWithChildren} from "@app/common/model/FCWithChildren";

import './checkbox.scss';

export type CheckboxProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    error?: string;
};

export const Checkbox: FCWithChildren<CheckboxProps> = forwardRef(({children, className, error, ...inputProps}, ref) => {
    return <div className={classNames('checkbox', className)}>
        <label>
            <input {...inputProps} type="checkbox" ref={ref} />

            <div className="checkbox__icon">
                <div>
                    <div className="checkbox__icon-inner">
                        <FontAwesomeIcon icon={faCheck} />
                    </div>
                </div>
            </div>

            <div className="checkbox__label">{children}</div>
        </label>
        {error && <div className="checkbox__error">{error}</div>}
    </div>
});

Checkbox.displayName = 'Checkbox';
