import {FC} from "react";
import {FieldPath} from "react-hook-form";

import {HelpIcon} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/HelpIcon/HelpIcon";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";
import {TaxReturnWithExtraFormFields} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormFieldsResponse";

import './groupHeader.scss';

export type GroupHeaderProps = {
    name: FieldPath<TaxReturnWithExtraFormFields>;
}

export const GroupHeader: FC<GroupHeaderProps> = ({name}) => {
    const formField = useFormField(name, true);

    return <div className="group-header">
        <div className="group-header__title">
            {formField.label}
            <HelpIcon formField={formField}/>
        </div>
        <div className="group-header__subtitle">{formField.placeholder}</div>
    </div>

}
