import {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams, useSearchParams} from "react-router-dom";

import {Loading} from "@app/common/components/Loading/Loading";
import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {ValidatedTaxReturn} from "@app/common/model/ValidatedTaxReturn";
import {FormFieldsContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/contexts/FormFieldsContext";
import {useFormFieldsApi} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormFieldsApi";
import {FormFieldsResponse} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormFieldsResponse";
import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {DeductiblesEdit} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/DeductiblesEdit";
import {IncomeEdit} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/IncomeEdit";
import {PersonalDataEdit} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/PersonalDataEdit";
import {TaxEdit} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/TaxEdit";

import './taxReturnEditPage.scss';

export type TaxReturnEditPageProps = {
    validatedTaxReturn: ValidatedTaxReturn;
}

export const TaxReturnEditPage: FC<TaxReturnEditPageProps> = ({validatedTaxReturn}) => {
    const {t} = useTranslation();
    const {step} = useParams<'step'>();
    const [searchParams] = useSearchParams();
    const validate = searchParams.get('validate');

    const [formFields, setFormFields] = useState<FormFieldsResponse|null|false>(null);

    const formFieldsApi = useFormFieldsApi();
    const showErrorToast = useShowErrorToast();

    useEffect(() => {
        if (formFields !== null) {
            return;
        }

        formFieldsApi()
            .then(setFormFields)
            .catch((error: unknown) => {
                showErrorToast(error as Error);
                setFormFields(false);
            });
    }, [formFields, formFieldsApi, showErrorToast]);

    return <Loading active={formFields === null} className="tax-return-edit-page">
        {step === TaxReturnStep.PAY && <div className="tax-return-edit-page__preview">
            <h3>{t('TaxReturnEditPage.preview.title')}</h3>
            <p>{t('TaxReturnEditPage.preview.subtitle')}</p>
        </div>}
        {formFields === false && <Fragment>TODO reload button</Fragment>}
        {formFields && <FormFieldsContext.Provider value={formFields}>
            <IncomeEdit
                validatedTaxReturn={validatedTaxReturn}
                opened={step === TaxReturnStep.INCOME}
                validate={step === TaxReturnStep.INCOME && validate !== null}
                preview={step === TaxReturnStep.PAY}
            />
            <PersonalDataEdit
                validatedTaxReturn={validatedTaxReturn}
                opened={step === TaxReturnStep.PERSONAL_DATA}
                validate={step === TaxReturnStep.PERSONAL_DATA && validate !== null}
                preview={step === TaxReturnStep.PAY}
            />
            <DeductiblesEdit
                validatedTaxReturn={validatedTaxReturn}
                opened={step === TaxReturnStep.DEDUCTIBLES}
                validate={step === TaxReturnStep.DEDUCTIBLES && validate !== null}
                preview={step === TaxReturnStep.PAY}
            />
            <TaxEdit
                validatedTaxReturn={validatedTaxReturn}
                opened={step === TaxReturnStep.TAX}
                validate={step === TaxReturnStep.TAX && validate !== null}
                preview={step === TaxReturnStep.PAY}
            />
        </FormFieldsContext.Provider>}
    </Loading>
}

export const TaxReturnEditPageTitle = 'TaxReturnEditPage.title';

export const TaxReturnEditPagePath = '/tax-return/:id/edit/:step?';
