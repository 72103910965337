import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {User} from "@app/common/model/User";
import {LoginRequest} from "@app/Login/LoginForm/LoginRequest";

export const useLogInApi = () => {
    const {i18n} = useTranslation();

    const api = useApi<User, LoginRequest>();

    return useCallback((payload: LoginRequest) => {
        return api(
            {url: '/login', method: HttpMethod.POST},
            payload,
            [{name: '_locale', value: i18n.language}]
        );
    }, [api, i18n.language]);
}
