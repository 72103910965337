import classNames from "classnames";
import {FC, PropsWithChildren, useEffect, useRef} from "react";
import {get, GlobalError, useFormContext, useWatch} from "react-hook-form";

import {Checkbox} from "@app/common/components/form/Checkbox/Checkbox";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {HelpIcon} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/HelpIcon/HelpIcon";
import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";
import {useFocusFirstInput} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFocusFirstInput";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";
import {FieldComponentProps} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FieldComponent";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";

import './checkboxGroup.scss';

export type CheckboxGroupProps = PropsWithChildren<FieldComponentProps & {
    className?: string;
    customFormField?: FormField;
    hideSubtitleWhenClosed?: boolean;
}>;

export const CheckboxGroup: FC<CheckboxGroupProps> = (({children, className, name, customFormField, hideSubtitleWhenClosed}) => {
    const editing = useEditingContext();
    const {register, formState} = useFormContext<TaxReturn>();

    const defaultFormField = useFormField(name);
    const formField = customFormField || defaultFormField;
    const error = get(formState.errors, name) as GlobalError|undefined;

    const {containerRef, focus} = useFocusFirstInput();

    const opened = useWatch({name}) as boolean;
    const firstRender = useRef<boolean>(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        if (opened) {
            focus();
        }
    }, [focus, opened]);


    if (!opened && !editing) {
        return null;
    }

    return <div className={classNames('checkbox-group', className)}>
        <div className="checkbox-group__title">
            <Checkbox
                {...register(name)}
                disabled={!editing}
                error={error?.message}
            >
                {formField.label}
            </Checkbox>
            <HelpIcon formField={formField}/>
        </div>
        {(!hideSubtitleWhenClosed || opened) && <div className="checkbox-group__subtitle">{formField.placeholder}</div>}
        {opened && <div className="checkbox-group__content" ref={containerRef}>
            {children}
        </div>}
    </div>
});

CheckboxGroup.displayName = 'CheckboxGroup';
