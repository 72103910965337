import {faCircleQuestion} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC} from "react";

import {HelpPopup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/HelpPopup";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";

import './helpIcon.scss';

export type HelpIconProps = {
    formField: FormField;
}

export const HelpIcon: FC<HelpIconProps> = ({formField}) => {
    return <HelpPopup formField={formField} className="help-icon">
        <FontAwesomeIcon icon={faCircleQuestion}/>
    </HelpPopup>
}
