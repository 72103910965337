import {FC, useEffect} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {Button} from "@app/common/components/Button/Button";
import {SelfEmploymentExpensesType} from "@app/common/model/TaxReturn/SelfEmploymentExpensesType";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {
    ExpensesTypeSelect
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/SelfEmploymentEdit/ExpensesTypeSelect/ExpensesTypeSelect";
import {
    TypeOfActivitySelect
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/SelfEmploymentEdit/TypeOfActivitySelect/TypeOfActivitySelect";

export type OtherActivityEditProps = {
    index: number;
    onRemove?: (index: number) => void;
}

export const OtherActivityEdit: FC<OtherActivityEditProps> = ({index, onRemove}) => {
    const {t} = useTranslation();
    const {setValue} = useFormContext<TaxReturn>();

    const mainExpensesType = useWatch<TaxReturn>({name: 'selfEmployment.expensesType'}) as SelfEmploymentExpensesType|''|undefined;

    useEffect(() => {
        if (!mainExpensesType) {
            setValue(`selfEmployment.otherActivities.${index}.expensesType`, null)
        }
    }, [index, mainExpensesType, setValue]);

    return <div className="child-edit">
        <FieldsRow>
            <TypeOfActivitySelect name={`selfEmployment.otherActivities.${index}.activityName`} />
            <AmountField name={`selfEmployment.otherActivities.${index}.income`} />
            <ExpensesTypeSelect
                name={`selfEmployment.otherActivities.${index}.expensesType`}
                allowReal={Boolean(mainExpensesType) && mainExpensesType === SelfEmploymentExpensesType.REAL}
                allowLump={Boolean(mainExpensesType) && mainExpensesType !== SelfEmploymentExpensesType.REAL}
            />
        </FieldsRow>
        <FieldsRow>
            {onRemove && <div className="child-edit__remove">
                <Button onClick={() => onRemove(index)} variant="secondary">
                    {t('OtherActivities.actions.remove')}
                </Button>
            </div>}
        </FieldsRow>
    </div>
}
