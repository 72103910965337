import {useContext} from "react";
import {FieldPath, get} from "react-hook-form";

import {FormFieldsContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/contexts/FormFieldsContext";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";
import {TaxReturnWithExtraFormFields} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormFieldsResponse";

export type FormFieldPlaceHolders = {
    placeholder: string;
    value: string;
}[];

export const useFormField = (name: FieldPath<TaxReturnWithExtraFormFields>, skipArrays: boolean = true, placeholders?: FormFieldPlaceHolders) => {
    const formFields = useContext(FormFieldsContext);

    if (!formFields) {
        throw new Error('Trying to use an uninstantiated FormFieldsContext');
    }

    const formField = get(formFields, skipArrays ? name.replace(/[0-9]+\./, '') : name) as FormField;

    if (placeholders) {
        placeholders.forEach(({placeholder, value}) => {
            formField.label = formField.label.replace(placeholder, value);
            formField.placeholder = formField.placeholder ? formField.placeholder.replace(placeholder, value) : undefined;
            formField.helpText = formField.helpText ? formField.helpText.replace(placeholder, value) : undefined;
        });
    }

    return formField;
}
