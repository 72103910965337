import {FC, useCallback} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import {Submit} from "@app/common/components/form/Submit/Submit";
import {TextInput} from "@app/common/components/form/TextInput/TextInput";
import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {DashboardPagePath} from "@app/Dashboard/DashboardPage";
import {LoginRequest} from "@app/Login/LoginForm/LoginRequest";
import {useLogInApi} from "@app/Login/LoginForm/useLogInApi";

import './loginForm.scss';

export const LoginForm: FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const showErrorToast = useShowErrorToast();

    const logInApi = useLogInApi();

    const {formState, handleSubmit, register} = useForm<LoginRequest>({
        defaultValues: {email: '', password: ''},
    });

    const onSubmit = useCallback<SubmitHandler<LoginRequest>>(async (data) => {
        try {
            const user = await logInApi(data);
            navigate(DashboardPagePath, {
                state: user,
            });
        } catch (error: unknown) {
            showErrorToast(error as Error);
        }
    }, [logInApi, navigate, showErrorToast])

    return <form onSubmit={handleSubmit(onSubmit)} className="login-form">
        <TextInput
            type="email"
            error={formState.errors.email?.message}
            {...register('email', {required: t('Login.form.email.required')})}
        >
            {t('Login.form.email.label')}
        </TextInput>
        <TextInput
            type="password"
            error={formState.errors.password?.message}
            {...register('password', {required: t('Login.form.password.required')})}
        >
            {t('Login.form.password.label')}
        </TextInput>
        <Submit>{t('Login.form.logIn')}</Submit>
    </form>

}
