import classNames from "classnames";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {NumericField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/NumericField";
import {FieldComponentProps} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FieldComponent";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";

export type AmountFieldProps = FieldComponentProps & {
    customFormField?: FormField;
    className?: string;
}

export const AmountField: FC<AmountFieldProps> = ({name, optional, customFormField, className}, ) => {
    const {i18n} = useTranslation();

    return <NumericField
        name={name}
        optional={optional}
        className={classNames('amount-field', className)}
        customFormField={customFormField}
        suffix={i18n.language === 'cs-CZ' ? 'Kč' : 'CZK'}
    />
};
