import classNames from "classnames";
import {DetailedHTMLProps, ButtonHTMLAttributes, forwardRef} from "react";

import {FCWithChildren} from "@app/common/model/FCWithChildren";

import './button.scss';

export type ButtonVariant = 'primary'|'secondary'|'success'|'warning'|'error';

export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    variant?: ButtonVariant;
};

export const Button: FCWithChildren<ButtonProps> = forwardRef(({children, variant = 'primary', className, ...buttonProps}, ref) => {
    return <button {...buttonProps} className={classNames('button', `button--${variant}`, className)} ref={ref}>
        {children}
    </button>
});

Button.displayName = 'Button';
