import {FC} from "react";
import {useTranslation} from "react-i18next";
import {Link, UIMatch, useMatches} from "react-router-dom";

import {TaxReturnTitle} from "@app/common/components/TaxReturnTitle/TaxReturnTitle";
import {useValidatedTaxReturns} from "@app/common/layouts/SecuredLayout/hooks/useValidatedTaxReturns";
import {TitleRouteHandle} from "@app/common/model/TitleRouteHandle";
import {TaxReturnDetailPageTitle} from "@app/TaxReturnDetail/TaxReturnDetailLayout";

import './breadcrumbs.scss';

export const Breadcrumbs: FC = () => {
    const {t} = useTranslation();
    const routerMatches = useMatches() as UIMatch<unknown, TitleRouteHandle|undefined>[];

    const {validatedTaxReturns} = useValidatedTaxReturns();

    const titleForMatch = (match: UIMatch<unknown, TitleRouteHandle>) => {
        if (match.handle.title === TaxReturnDetailPageTitle) {
            const foundTaxReturn = validatedTaxReturns.find(
                (validatedTaxReturn) => validatedTaxReturn.taxReturn.id === match.params.id
            )

            if (foundTaxReturn) {
                return <TaxReturnTitle taxReturn={foundTaxReturn.taxReturn} inline={true} />
            }
        }

        return t(match.handle.title);
    }

    return <div className="breadcrumbs">
        {routerMatches
            .filter<UIMatch<unknown, TitleRouteHandle>>((match): match is UIMatch<unknown, TitleRouteHandle> => match.handle !== undefined)
            .map((match) => (<span className="breadcrumbs__item" key={match.id}>
                <Link to={match.pathname} key={match.id}>
                    {titleForMatch(match)}
                </Link>
            </span>))}
    </div>
}
