import classNames from "classnames";
import {Fragment, MouseEventHandler, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {Popover, PositionTransform, PositionTransformValue} from "react-tiny-popover";
import striptags from "striptags";

import {Button} from "@app/common/components/Button/Button";
import {FCWithChildren} from "@app/common/model/FCWithChildren";
import {useHelpImageUrl} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/useHelpImageUrl";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";

import './helpPopup.scss';

export type HelpPopupProps = {
    formField: FormField;
    className?: string;
}

export const HelpPopup: FCWithChildren<HelpPopupProps> = ({children, formField, className}) => {
    const {t} = useTranslation();
    const [popupOpened, setPopupOpened] = useState<boolean>(false);
    const [modalOpened, setModalOpened] = useState<boolean>(false);

    const helpImageUrl = useHelpImageUrl();

    const transform = useCallback<Exclude<PositionTransform, PositionTransformValue>>(
        ({hasViolations, popoverRect}) => {
            if (!hasViolations) {
                return {top: 0, left: 0};
            }

            return {
                top: -1 * popoverRect.top,
                left: -1 * popoverRect.left,
            }
        }, []);

    const onPopupOpen = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
        event.preventDefault();
        setPopupOpened(true);
    }, []);

    const onModalOpen = useCallback(() => {
        setPopupOpened(false);
        setModalOpened(true);
    }, []);

    if ((!formField.helpText || formField.helpText === '') && (!formField.helpImage || formField.helpImage === '')) {
        return null;
    }

    return <div className={classNames('help-popup', className)}>
        <Modal
            isOpen={modalOpened}
            onRequestClose={() => setModalOpened(false)}
            ariaHideApp={false}
        >
            {formField.helpImage && formField.helpImage !== '' && <div className="help-modal" onClick={() => setModalOpened(false)}>
                <img src={helpImageUrl(formField.helpImage)} alt={formField.helpImage} />
            </div>}
        </Modal>
        <Popover
            isOpen={popupOpened}
            content={<Fragment>
                {formField.helpText && <div className="help-popup__content">
                    <div className="help-popup__columns">
                        {formField.helpImage && formField.helpImage !== '' && <div onClick={onModalOpen}>
                            <img src={helpImageUrl(formField.helpImage)} alt={formField.helpImage} />
                        </div>}
                        <div dangerouslySetInnerHTML={{__html: striptags(formField.helpText, ['p', 'a', 'br'])}}/>
                    </div>
                    <div className="help-popup__buttons">
                        <Button variant="secondary" onClick={() => setPopupOpened(false)}>
                            {t('HelpPopup.actions.close')}
                        </Button>
                    </div>
                </div>}
            </Fragment>}
            transform={transform}
            transformMode="relative"
        >
            <div className="help-popup__opener" onClick={onPopupOpen}>
                {children}
            </div>
        </Popover>
    </div>
}
