import {FC} from "react";
import {useWatch} from "react-hook-form";

import {HealthInsuranceOffice} from "@app/common/model/TaxReturn/HealthInsuranceOffice";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {TaxReturnCalculation} from "@app/common/model/TaxReturn/TaxReturnCalculation";
import {CheckboxField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxField/CheckboxField";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {
    NumberOfMonthsField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumberOfMonthsField/NumberOfMonthsField";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {NumericField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/NumericField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {GroupHeader} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/GroupHeader/GroupHeader";
import {
    AccountEdit
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/SelfEmploymentStatements/AccountEdit/AccountEdit";
import {
    HealthInsuranceInfo
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/SelfEmploymentStatements/HealthInsuranceEdit/HealthInsuranceInfo/HealthInsuranceInfo";
import {
    HealthInsuranceOfficeSelect
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/SelfEmploymentStatements/HealthInsuranceEdit/HealthInsuranceOfficeSelect/HealthInsuranceOfficeSelect";
import {
    VzpBranchSelect
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/SelfEmploymentStatements/HealthInsuranceEdit/VzpBranchSelect/VzpBranchSelect";
import {
    MonthsSelection
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/SelfEmploymentStatements/SocialInsuranceEdit/MonthsSelection/MonthsSelection";

import './healthInsuranceEdit.scss';

export type HealthInsuranceEditProps = {
    calculation: TaxReturnCalculation;
}

export const HealthInsuranceEdit: FC<HealthInsuranceEditProps> = ({calculation}) => {

    const healthInsuranceOffice = useWatch<TaxReturn, 'socialInsurance.healthInsurance.healthInsuranceOffice'>({name: 'socialInsurance.healthInsurance.healthInsuranceOffice'});
    const selfEmployedWholeYear = useWatch<TaxReturn, 'socialInsurance.selfEmployedWholeYear'>({name: 'socialInsurance.selfEmployedWholeYear'});
    const selfEmployedMonths = useWatch<TaxReturn, 'socialInsurance.selfEmployedMonths'>({name: 'socialInsurance.selfEmployedMonths'});
    const year = useWatch<TaxReturn>({name: 'year'}) as number;

    return <CheckboxGroup name="socialInsurance.healthInsurance.summaryRequired" className="health-insurance-edit">
        <FieldsRow>
            <NumericField name="socialInsurance.healthInsurance.insureeId" />
            <HealthInsuranceOfficeSelect name="socialInsurance.healthInsurance.healthInsuranceOffice" />
            {healthInsuranceOffice === HealthInsuranceOffice.HI111 && <VzpBranchSelect name="socialInsurance.healthInsurance.vzpBranch" />}
        </FieldsRow>
        <GroupHeader name="socialInsurance.healthInsurance.bankAccount" />
        <AccountEdit accountPath="socialInsurance.healthInsurance.bankAccount" />
        <FieldsRow>
            <AmountField name="socialInsurance.healthInsurance.paidAdvances" />
            {year < 2020 && <NumberOfMonthsField name="socialInsurance.healthInsurance.monthsInsured" />}
        </FieldsRow>
        {year >= 2020 && <div className="checkbox-group social-insurance-edit__months-group">
            <GroupHeader name="socialInsurance.healthInsurance.monthsInsuredInfo" />
            <MonthsSelection
                wholeMonthName="socialInsurance.healthInsurance.monthsInsuredInfo.wholeYear"
                monthsName="socialInsurance.healthInsurance.monthsInsuredInfo.months"
                wholeYearDisabled={!selfEmployedWholeYear}
                disabledMonths={selfEmployedMonths}
            />
        </div>}
        {year >= 2022 && <div className="checkbox-group social-insurance-edit__months-group">
            <GroupHeader name="socialInsurance.healthInsurance.lumpPayerMonthsInfo" />
            <MonthsSelection
                wholeMonthName="socialInsurance.healthInsurance.lumpPayerMonthsInfo.wholeYear"
                monthsName="socialInsurance.healthInsurance.lumpPayerMonthsInfo.months"
                wholeYearDisabled={!selfEmployedWholeYear}
                disabledMonths={selfEmployedMonths}
            />
        </div>}
        <FieldsRow>
            <CheckboxField name="socialInsurance.healthInsurance.noMinimumNextYear" />
            <CheckboxField name="socialInsurance.healthInsurance.employedNextYear" />
            {year > 2018 && <CheckboxField name="socialInsurance.healthInsurance.correctiveStatement" />}
        </FieldsRow>
        <HealthInsuranceInfo calculation={calculation} />
    </CheckboxGroup>
}
