import {useTranslation} from "react-i18next";

import {FCWithChildren} from "@app/common/model/FCWithChildren";

import './beforeYouStart.scss';

export const BeforeYouStart: FCWithChildren = ({children}) => {
    const {t} = useTranslation();

    return <div className="before-you-start">
        <div>
            <strong>{t('BeforeYouStart.title')}</strong>{' '}
            {t('BeforeYouStart.subtitle')}
        </div>
        <div>{children}</div>
    </div>
}
