import {FC} from "react";

import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";
import {AddressEdit} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/AddressEdit/AddressEdit";

export const CzechResidencyAddress: FC = () => {
    const checkboxFormField = useFormField('sections.czechResidencyAddress');

    return <CheckboxGroup name="sections.czechResidencyAddress" customFormField={checkboxFormField}>
        <AddressEdit name="czechResidencyAddress" />
    </CheckboxGroup>
}
