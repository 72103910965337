import {FC, Fragment} from "react";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";

import {I18Provider} from "@app/App/I18Provider/I18Provider";
import {SecuredLayout} from "@app/common/layouts/SecuredLayout/SecuredLayout";
import {childPath} from "@app/common/utils/childPath";
import {DashboardPage, DashboardPagePath, DashboardPageTitle} from "@app/Dashboard/DashboardPage";
import {LoginPage, LoginPagePath} from "@app/Login/LoginPage";
import {NotFoundPage, NotFoundPageTitle} from "@app/NotFound/NotFoundPage";
import {
    TaxReturnDetailLayout,
    TaxReturnDetailPagePath,
    TaxReturnDetailPageTitle
} from "@app/TaxReturnDetail/TaxReturnDetailLayout";
import {UserSettingsPage, UserSettingsPagePath, UserSettingsPageTitle} from "@app/UserSettings/UserSettingsPage";

import './app.scss';

export const App: FC = () => {
    const router = createBrowserRouter(createRoutesFromElements(
        <Fragment>
            <Route path={LoginPagePath} element={<LoginPage />} />
            <Route path={DashboardPagePath} element={<SecuredLayout />} handle={{title: DashboardPageTitle}} >
                <Route index element={<DashboardPage />} />
                <Route path={`${childPath(DashboardPagePath, TaxReturnDetailPagePath)}/*`} element={<TaxReturnDetailLayout />} handle={{title: TaxReturnDetailPageTitle}} />
                <Route path={childPath(DashboardPagePath, UserSettingsPagePath)} element={<UserSettingsPage />} handle={{title: UserSettingsPageTitle}} />
                <Route path="*" element={<NotFoundPage />} handle={{title: NotFoundPageTitle}} />
            </Route>
        </Fragment>
    ), {
        future: {
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_skipActionErrorRevalidation: true,
            v7_fetcherPersist: true,
            v7_relativeSplatPath: true,
        }
    });

    return <I18Provider>
        <RouterProvider router={router} />
    </I18Provider>
}
