import classNames from "classnames";
import {Fragment, MouseEventHandler, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {Popover, PositionTransform, PositionTransformValue} from "react-tiny-popover";

import {Button} from "@app/common/components/Button/Button";
import {FCWithChildren} from "@app/common/model/FCWithChildren";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";

import './helpPopup.scss';

export type HelpPopupProps = {
    formField: FormField;
    className?: string;
}

export const HelpPopup: FCWithChildren<HelpPopupProps> = ({children, formField, className}) => {
    const {t} = useTranslation();
    const [opened, setOpened] = useState<boolean>(false);

    const transform = useCallback<Exclude<PositionTransform, PositionTransformValue>>(
        ({hasViolations, popoverRect}) => {
            if (!hasViolations) {
                return {top: 0, left: 0};
            }

            return {
                top: -1 * popoverRect.top,
                left: -1 * popoverRect.left,
            }
        }, []);

    const onOpen = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
        event.preventDefault();
        setOpened(true);
    }, [])

    if ((!formField.helpText || formField.helpText === '') && (!formField.helpImage || formField.helpImage === '')) {
        return null;
    }

    return <div className={classNames('help-popup', className)}>
        <Popover
            isOpen={opened}
            content={<Fragment>
                {formField.helpText && <div className="help-popup__content">
                    <div dangerouslySetInnerHTML={{__html: formField.helpText}}/>
                    <div className="help-popup__buttons">
                        <Button variant="secondary" onClick={() => setOpened(false)}>
                            {t('HelpPopup.actions.close')}
                        </Button>
                    </div>
                </div>}
            </Fragment>}
            transform={transform}
            transformMode="relative"
        >
            <div className="help-popup__opener" onClick={onOpen}>
                {children}
            </div>
        </Popover>
    </div>
}
