import classNames from "classnames";
import {
    DetailedHTMLProps,
    forwardRef, Fragment,
    InputHTMLAttributes,
    KeyboardEventHandler,
    useCallback
} from "react";

import {FCWithChildren} from "@app/common/model/FCWithChildren";

import './numericInput.scss';

export type NumericInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    error?: string;
    suffix?: string;
};

export const NumericInput: FCWithChildren<NumericInputProps> = forwardRef(({children, className, error, suffix, ...inputProps}, ref) => {
    const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>((event) => {
        // Firefox fix - filter out non numeric characters
        if (event.key.length === 1 && !event.key.match(/[0-9]/)) {
            event.preventDefault();
        }
    }, []);

    return <Fragment>
        <div className={classNames('text-input', 'numeric-input', error ? 'text-input--with-error' : null, className)}>
            <label>
                <div className="text-input__label">{children}</div>
                <div className="numeric-input__input">
                    <input {...inputProps} inputMode="numeric" onKeyDown={onKeyDown}  type="number" ref={ref}/>
                    {suffix && <span className="numeric-input__suffix">{suffix}</span>}
                </div>
            </label>
        </div>
        {error && <div className="text-input__error">{error}</div>}
    </Fragment>
})

NumericInput.displayName = 'NumericInput';
