import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC, useCallback, useEffect} from "react";
import {useFieldArray} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {Button} from "@app/common/components/Button/Button";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";
import {
    OtherIncomeEdit
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/OtherIncomes/OtherIncomeEdit/OtherIncomeEdit";

export const OtherIncomes: FC = () => {
    const {t} = useTranslation();
    const editing = useEditingContext();
    const {fields, append, remove} = useFieldArray<TaxReturn, 'otherIncomes'>({name: 'otherIncomes'});

    const onOtherIncomeAdd = useCallback(() => {
        append({generatedAbroad: false});
    }, [append]);

    useEffect(() => {
        if (fields.length === 0) {
            append({generatedAbroad: false});
        }
    }, [append, fields.length]);

    return <CheckboxGroup name="sections.otherIncomes">
        {fields.map((field, index) => (
            <OtherIncomeEdit index={index} onRemove={editing && fields.length > 1 ? remove : undefined} key={field.id} />
        ))}
        {editing && <Button onClick={onOtherIncomeAdd} variant="secondary">
            <FontAwesomeIcon icon={faPlus} />{' '}
            {t('OtherIncomeEdit.actions.add')}
        </Button>}

    </CheckboxGroup>
}
