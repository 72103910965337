import {faCalendar, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {
    DetailedHTMLProps,
    forwardRef,
    Fragment,
    InputHTMLAttributes, MouseEventHandler,
    useCallback,
} from "react";

import {FCWithChildren} from "@app/common/model/FCWithChildren";

import './datePickerInput.scss';

export type DatePickerInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    onClear?: () => void;
    error?: string;
}

export const DatePickerInput: FCWithChildren<DatePickerInputProps> = forwardRef<HTMLDivElement, DatePickerInputProps>(({children, onClear, error, className, ...inputProps}, ref) => {
    const {value} = inputProps;

    const clear = useCallback<MouseEventHandler>((event) => {
        event.preventDefault();

        if (!onClear) {
            return;
        }

        onClear();
    }, [onClear])

    return <Fragment>
        <div className={classNames('date-picker-input', 'text-input', error && 'text-input--with-error', className)}>
            <label>
                <div className="text-input__label">{children}</div>
                <div className="date-picker-input__input" ref={ref}>
                    <input {...inputProps} />
                    {onClear && value && <FontAwesomeIcon icon={faTimes} onClick={clear} className="date-picker-input__icon date-picker-input__icon--delete" />}
                    <FontAwesomeIcon icon={faCalendar} className="date-picker-input__icon" />
                </div>
            </label>
        </div>
        {error && <div className="text-input__error">{error}</div>}
    </Fragment>
});

DatePickerInput.displayName = 'DatePickerInput';
