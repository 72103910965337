import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC, useCallback} from "react";
import {useFieldArray, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {Button} from "@app/common/components/Button/Button";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";
import {
    AbroadIncomeEdit
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/AbroadIncomes/AbroadIncomeEdit/AbroadIncomeEdit";

export const AbroadIncomes: FC = () => {
    const {t} = useTranslation();
    const editing = useEditingContext();

    const year = useWatch<TaxReturn>({name: 'year'}) as number;
    const {fields, append, remove} = useFieldArray<TaxReturn, 'abroadIncomes'>({name: 'abroadIncomes'});

    const onAbroadIncomeAdd = useCallback(() => {
        append({});
    }, [append]);

    if (year <= 2018) {
        return null;
    }

    return <CheckboxGroup name="sections.abroadIncomes">
        {fields.map((field, index) => (
            <AbroadIncomeEdit index={index} onRemove={editing && fields.length > 1 ? remove : undefined} key={field.id} />
        ))}
        {editing && <Button onClick={onAbroadIncomeAdd} variant="secondary">
            <FontAwesomeIcon icon={faPlus} />{' '}
            {t('AbroadIncomeEdit.actions.add')}
        </Button>}

    </CheckboxGroup>
}
