import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";

export type TaxReturnStepTitleProps = {
    step: TaxReturnStep;
}

export const TaxReturnStepTitle : FC<TaxReturnStepTitleProps> = ({step}) => {
    const {t} = useTranslation();

    const stepsToNumbers: Map<TaxReturnStep, number> = new Map<TaxReturnStep, number>();
    stepsToNumbers.set(TaxReturnStep.INCOME, 1);
    stepsToNumbers.set(TaxReturnStep.PERSONAL_DATA, 2);
    stepsToNumbers.set(TaxReturnStep.DEDUCTIBLES, 3);
    stepsToNumbers.set(TaxReturnStep.TAX, 4);

    return <Fragment>
        {stepsToNumbers.get(step)}. {t(`TaxReturnStepTitle.steps.${step}`)}
    </Fragment>
}
