export enum VzpBranch {
    PRAGUE = 'PRAGUE',
    SOUTH_BOHEMIA = 'SOUTH_BOHEMIA',
    WEST_BOHEMIA = 'WEST_BOHEMIA',
    NORTH_BOHEMIA = 'NORTH_BOHEMIA',
    EAST_BOHEMIA = 'EAST_BOHEMIA',
    SOUTH_MORAVIA = 'SOUTH_MORAVIA',
    NORTH_MORAVIA = 'NORTH_MORAVIA',
    KARLOVY_VARY = 'KARLOVY_VARY',
    SILESIA = 'SILESIA',
}
