import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC} from "react";
import {FieldPath, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {TaxReturnState} from "@app/common/model/TaxReturn/TaxReturnState";
import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";

import './readOnlyFlag.scss';

export type ReadOnlyFlagProps = {
    name: FieldPath<TaxReturn>;
}

export const ReadOnlyFlag: FC<ReadOnlyFlagProps> = ({name}) => {
    const {t} = useTranslation();
    const formField = useFormField(name);
    const editing = useEditingContext();
    const taxReturnState = useWatch<TaxReturn>({name: 'state'}) as TaxReturnState;
    const isNew = taxReturnState ===  TaxReturnState.CREATED;

    if (isNew || !editing) {
        return null;
    }

    return <div className="read-only-flag">
        <FontAwesomeIcon icon={faExclamationTriangle} />
        {t('ReadOnlyFlag.readOnly', {field: formField.label})}
    </div>
}
