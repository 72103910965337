import {FC} from "react";
import {useWatch} from "react-hook-form";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {NumericField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/NumericField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

export const Kindergarten: FC = () => {
    const year = useWatch<TaxReturn>({name: 'year'}) as number;

    if (year >= 2024) {
        return null;
    }

    return <CheckboxGroup name="sections.kindergarten">
        <FieldsRow>
            <NumericField name="deductibles.kindergartenCount" />
            <AmountField name="deductibles.kindergarten" />
        </FieldsRow>
    </CheckboxGroup>
}
