import {FC, useMemo} from "react";
import {FieldPath} from "react-hook-form";

import {SelectOption} from "@app/common/components/form/Select/Select";
import {OtherIncomeType} from "@app/common/model/TaxReturn/OtherIncomeType";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {SelectField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/SelectField";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";

export type OtherIncomesTypeSelectProps = {
    name: FieldPath<TaxReturn>
}

export const OtherIncomesTypeSelect: FC<OtherIncomesTypeSelectProps> = ({name}) => {
    const occasionalFormField = useFormField('otherIncomes.0.types.occasional');
    const saleOfRealEstateFormField = useFormField('otherIncomes.0.types.saleOfRealEstate');
    const saleOfRealMovableAssetsFormField = useFormField('otherIncomes.0.types.saleOfMovableAssets');
    const saleOfSharesFormField = useFormField('otherIncomes.0.types.saleOfShares');
    const otherFormField = useFormField('otherIncomes.0.types.other');

    const options: SelectOption[] = useMemo(() => ([
        {value: OtherIncomeType.OCCASIONAL_ACTIVITY, label: occasionalFormField.label},
        {value: OtherIncomeType.SALE_OF_REAL_ESTATE, label: saleOfRealEstateFormField.label},
        {value: OtherIncomeType.SALE_OF_MOVABLE_ASSETS, label: saleOfRealMovableAssetsFormField.label},
        {value: OtherIncomeType.SALE_OF_SHARES_AND_SECURITIES, label: saleOfSharesFormField.label},
        {value: OtherIncomeType.OTHER, label: otherFormField.label},
    ]), [occasionalFormField.label, otherFormField.label, saleOfRealEstateFormField.label, saleOfRealMovableAssetsFormField.label, saleOfSharesFormField.label]);

    return <SelectField
        name={name}
        options={options}
    />

}
