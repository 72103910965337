import {generatePath} from "react-router-dom";

import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {TaxReturnEditPagePath} from "@app/TaxReturnDetail/TaxReturnEdit/TaxReturnEditPage";

export const taxReturnSectionTo = (taxReturnId: string, step: TaxReturnStep, validate: boolean) => {
    return {
        pathname: generatePath(TaxReturnEditPagePath, {id: taxReturnId, step}),
        search: validate ? "validate=1" : undefined,
    };
}
