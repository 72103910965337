import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";

export const getNextStep = (step: TaxReturnStep): TaxReturnStep|null => {
    switch (step) {
        case TaxReturnStep.INCOME: return TaxReturnStep.PERSONAL_DATA;
        case TaxReturnStep.PERSONAL_DATA: return TaxReturnStep.DEDUCTIBLES;
        case TaxReturnStep.DEDUCTIBLES: return TaxReturnStep.TAX;
        case TaxReturnStep.TAX: return TaxReturnStep.PAY;
        case TaxReturnStep.PAY: return null;
    }
}
