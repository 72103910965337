import {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useDebouncedCallback} from "use-debounce";

import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {useValidatedTaxReturns} from "@app/common/layouts/SecuredLayout/hooks/useValidatedTaxReturns";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {usePatchTaxReturnApi} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/usePatchTaxReturnApi";
import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {validationTargetForStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/utils/validationTargetForStep";

const PATCH_DEBOUNCE_TIMEOUT = 400;
const PATCH_DEBOUNCE_MAX_WAIT = 2000;

export const usePatchForm = (
    taxReturn: TaxReturn,
    step: TaxReturnStep,
    defaultValidate: boolean,
) => {

    const form = useForm<TaxReturn>({
        mode: "onSubmit",
        defaultValues: taxReturn,
    });

    const [submitting, setSubmitting] = useState<boolean>(false);

    const {updateTaxReturn} = useValidatedTaxReturns();
    const validationTarget = validationTargetForStep(step);

    const patchApi = usePatchTaxReturnApi(taxReturn.id, validationTarget);
    const showErrorToast = useShowErrorToast();

    const submitAndValidate = useCallback(async (submittedTaxReturn: TaxReturn, validate: boolean = true, focus: boolean = true) => {
        setSubmitting(true);
        try {
            const response = await patchApi(submittedTaxReturn);

            if (validate) {
                form.clearErrors();
                let focused = false;
                response.validation.forEach((error) => {
                    form.setError(error.field, {message: error.error});
                    if (focus && !focused) {
                        form.setFocus(error.field);
                        focused = true;
                    }
                });
            }

            await updateTaxReturn(submittedTaxReturn, validationTarget, response.validation.length === 0);

            return response.validation.length === 0;
        } catch (error: unknown) {
            showErrorToast(error as Error);
        }
        finally {
            setSubmitting(false);
        }
    }, [form, patchApi, showErrorToast, updateTaxReturn, validationTarget]);

    const onChange = useDebouncedCallback((submittedTaxReturn: TaxReturn) => {
        void submitAndValidate(submittedTaxReturn, defaultValidate, false);
    }, PATCH_DEBOUNCE_TIMEOUT, {maxWait: PATCH_DEBOUNCE_MAX_WAIT});

    const onSubmit = useCallback(async (submittedTaxReturn: TaxReturn) => {
        onChange.cancel();
        return submitAndValidate(submittedTaxReturn, true, true);
    }, [onChange, submitAndValidate]);

    useEffect(() => {
        if (defaultValidate && form.formState.submitCount === 0) {
            form.handleSubmit(onSubmit)();
        }
    }, [defaultValidate, form, onSubmit]);

    useEffect(() => {
        const unsubscribe = form
            .watch((values) => onChange(values as TaxReturn))
            .unsubscribe;

        return () => {
            unsubscribe();
            onChange.flush();
        }
    }, [onChange, form]);

    return {
        form,
        onSubmit,
        submitting,
    }
}
