import {useCallback} from "react";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";

type CreateTaxReturnRequest = {
    year: number;
    firstName: string;
    lastName: string;
}

export const useCreateTaxReturnApi = () => {
    const api = useApi<TaxReturn, CreateTaxReturnRequest>();

    return useCallback((payload: CreateTaxReturnRequest) => {
        return api(
            {url: '/tax-returns/', method: HttpMethod.POST},
            payload,
        );
    }, [api]);
}
