import {FC, useMemo} from "react";
import {FieldPath} from "react-hook-form";

import {SelectOption} from "@app/common/components/form/Select/Select";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {SelectField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/SelectField";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";

export type LeaseExpensesTypeSelectProps = {
    name: FieldPath<TaxReturn>
}

export const LeaseExpensesTypeSelect: FC<LeaseExpensesTypeSelectProps> = ({name}) => {
    const realFormField = useFormField('leaseIncome.expensesTypes.real');
    const lump30FormField = useFormField('leaseIncome.expensesTypes.lump_30');

    const options: SelectOption[] = useMemo(() => ([
        {value: 'real', label: realFormField.label},
        {value: 'lump_30', label: lump30FormField.label}
    ]), [lump30FormField.label, realFormField.label]);

    return <SelectField
        name={name}
        options={options}
    />

}
