import {useEffect, useState} from "react";

export const useStateProp = <PropType>(propValue: PropType) => {
    const [state, setState] = useState<PropType>(propValue);

    useEffect(() => {
        setState(propValue);
    }, [propValue]);

    return [state, setState] as const;
}
