import classNames from "classnames";
import {FC} from "react";
import {get, GlobalError, useFormContext} from "react-hook-form";

import {NumericInput} from "@app/common/components/form/NumericInput/NumericInput";
import {RequiredStar} from "@app/common/components/form/RequiredStar/RequiredStar";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {HelpIcon} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/HelpIcon/HelpIcon";
import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";
import {FieldComponentProps} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FieldComponent";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";

export type NumericFieldProps = FieldComponentProps & {
    className?: string;
    customFormField?: FormField;
    customDisabled?: boolean;
    suffix?: string;
}

export const NumericField: FC<NumericFieldProps> = ({name, optional, suffix, customFormField, customDisabled, className}) => {
    const editing = useEditingContext();
    const {register, formState}  = useFormContext<TaxReturn>();

    const defaultFormField = useFormField(name);
    const formField = customFormField || defaultFormField;

    const error = get(formState.errors, name) as GlobalError|undefined;

    return <div className={classNames('numeric-field', className)}>
        <NumericInput
            {...register(name)}
            disabled={customDisabled || !editing}
            placeholder={formField.placeholder}
            error={error?.message}
            suffix={suffix}
        >
            {formField.label}
            <RequiredStar optional={optional} />
            <HelpIcon formField={formField}/>
        </NumericInput>
    </div>
};
