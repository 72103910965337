import classNames from "classnames";
import {getHours, getMinutes, setHours, setMinutes} from "date-fns";
import {intlFormat} from "date-fns/intlFormat";
import {forwardRef, Fragment, PropsWithChildren, useCallback, useState} from "react";
import {DayPicker, DayPickerProps} from "react-day-picker";
import {useTranslation} from "react-i18next";
import {Popover, PositionTransform, PositionTransformValue} from "react-tiny-popover";

import {DatePickerInput} from "@app/common/components/form/DatePicker/DatePickerInput/DatePickerInput";

import 'react-day-picker/dist/style.css';
import './datePicker.scss';

export type DatePickerProps = {
    dayPickerProps?: DayPickerProps;
    value: Date|null;
    onDateSelect: (date: Date|null) => void;
    error?: string;
    className?: string;
    disabled?: boolean;
};

export const DatePicker = forwardRef<HTMLInputElement, PropsWithChildren<DatePickerProps>>(({
    dayPickerProps,
    value,
    onDateSelect,
    children,
    error,
    className,
    disabled,
}, ref) => {
    const {i18n} = useTranslation();
    const [opened, setOpened] = useState<boolean>(false);

    const toggle = useCallback(() => {
        setOpened(!opened);
    }, [opened]);

    const onDayClick = useCallback((day: Date) => {
        const selectedDay = !value ? day : setHours(setMinutes(day, getMinutes(value)), getHours(value));
        onDateSelect(selectedDay);
        toggle();
    }, [onDateSelect, toggle, value]);

    const onClear = useCallback(() => {
        onDateSelect(null);
    }, [onDateSelect])

    const transform = useCallback<Exclude<PositionTransform, PositionTransformValue>>(
        ({hasViolations, popoverRect}) => {
            if (!hasViolations) {
                return {top: 0, left: 0};
            }

            return {
                top: -1 * popoverRect.top,
                left: -1 * popoverRect.left,
            }
        }, [])

    return <Fragment>
        <div className={classNames('date-picker', 'text-input', error && 'text-input--with-error', className)}>
            <label>
                <div className="date-picker__label text-input__label">{children}</div>
                <Popover
                    isOpen={opened}
                    content={<div className="date-picker__content">
                        <DayPicker
                            {...dayPickerProps}
                            captionLayout="dropdown-years"
                            onDayClick={onDayClick}
                        />
                    </div>}
                    transform={transform}
                    transformMode="relative"
                    onClickOutside={toggle}

                >
                    <div>
                        <DatePickerInput
                            onClear={onClear}
                            onClick={toggle}
                            defaultValue={value ? intlFormat(value, {locale: i18n.language}) : ''}
                            disabled={disabled}
                            ref={ref}
                        />
                    </div>
                </Popover>
            </label>
        </div>
        {error && <div className="text-input__error">{error}</div>}
    </Fragment>
});

DatePicker.displayName = 'DatePicker';
