import {FC} from "react";
import {useTranslation} from "react-i18next";

import {Button} from "@app/common/components/Button/Button";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {NumericField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/NumericField";
import {
    CountrySelectField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/CountrySelectField/CountrySelectField";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

import './abroadIncomeEdit.scss';

export type AbroadIncomeEditProps = {
    index: number;
    onRemove?: (index: number) => void;
}

export const AbroadIncomeEdit: FC<AbroadIncomeEditProps> = ({index, onRemove}) => {
    const {t} = useTranslation();

    return <div className="abroad-income-edit">
        <FieldsRow>
            <CountrySelectField name={`abroadIncomes.${index}.country`} />
            <TextField name={`abroadIncomes.${index}.employer`} />
            <AmountField name={`abroadIncomes.${index}.income`} />
        </FieldsRow>
        <FieldsRow>
            <AmountField name={`abroadIncomes.${index}.taxCzk`} />
            <NumericField name={`abroadIncomes.${index}.taxLocal`} />
            {onRemove && <div className="abroad-income-edit__remove">
                <Button onClick={() => onRemove(index)} variant="secondary">
                    {t('AbroadIncomeEdit.actions.remove')}
                </Button>
            </div>}
        </FieldsRow>
    </div>

}
