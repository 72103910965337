import {FC} from "react";
import {get, GlobalError, useFormContext} from "react-hook-form";

import {RequiredStar} from "@app/common/components/form/RequiredStar/RequiredStar";
import {Select, SelectOption} from "@app/common/components/form/Select/Select";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {HelpIcon} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/HelpIcon/HelpIcon";
import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";
import {FieldComponentProps} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FieldComponent";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";

export type SelectFieldProps = FieldComponentProps & {
    options: SelectOption[];
    customFormField?: FormField;
    customPlaceholder?: string;
}

export const SelectField: FC<SelectFieldProps> = ({name, optional, options, customFormField, customPlaceholder}) => {
    const editing = useEditingContext();
    const {register, formState} = useFormContext<TaxReturn>();

    const defaultFormField = useFormField(name);
    const formField = customFormField || defaultFormField;

    const error = get(formState.errors, name) as GlobalError|undefined;

    return <div>
        <Select
            {...register(name)}
            disabled={!editing}
            placeholder={customPlaceholder || formField.placeholder}
            options={options}
            error={error?.message}
        >
            {formField.label}
            <RequiredStar optional={optional} />
            <HelpIcon formField={formField}/>
        </Select>
    </div>
};
