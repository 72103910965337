import {FC, useCallback, useEffect} from "react";
import {FieldPath, get, GlobalError, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import {Select, SelectOption} from "@app/common/components/form/Select/Select";
import {Submit} from "@app/common/components/form/Submit/Submit";
import {TextInput} from "@app/common/components/form/TextInput/TextInput";
import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {useValidatedTaxReturns} from "@app/common/layouts/SecuredLayout/hooks/useValidatedTaxReturns";
import {useCreateTaxReturnApi} from "@app/Dashboard/CreateTaxReturnForm/useCreateTaxReturnApi";
import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {taxReturnSectionTo} from "@app/TaxReturnDetail/TaxReturnEdit/common/utils/taxReturnSectionTo";

import './createTaxReturnForm.scss';

type CreateTaxReturnFormData = {
    year: string;
    firstName: string;
    lastName: string;
}

export const CreateTaxReturnForm: FC = () => {
    const {t} = useTranslation();

    const {handleSubmit, register, setFocus, formState} = useForm<CreateTaxReturnFormData>({
        defaultValues: {
            year: '2024',
            firstName: '',
            lastName: '',
        }
    });

    const getError = useCallback((name: FieldPath<CreateTaxReturnFormData>) => {
        const error = get(formState.errors, name) as GlobalError|undefined;
        return error?.message;
    }, [formState.errors]);

    const navigate = useNavigate();
    const {createTaxReturn} = useValidatedTaxReturns();

    const showErrorToast = useShowErrorToast();
    const createTaxReturnApi = useCreateTaxReturnApi();
    const onSubmit = useCallback(async (data: CreateTaxReturnFormData) => {
        try {
            const newTaxReturn = await createTaxReturnApi({
                firstName: data.firstName,
                lastName: data.lastName,
                year: parseInt(data.year, 10),
            });

            createTaxReturn(newTaxReturn);
            navigate(taxReturnSectionTo(newTaxReturn.id, TaxReturnStep.INCOME, false));
        } catch (error: unknown) {
            showErrorToast(error as Error);
        }
    }, [createTaxReturn, createTaxReturnApi, navigate, showErrorToast]);

    useEffect(() => {
        setFocus('firstName');
    }, [setFocus]);

    const yearsAvailable: SelectOption[] = [
        {value: '2021', label: '2021'},
        {value: '2022', label: '2022'},
        {value: '2023', label: '2023'},
        {value: '2024', label: '2024'},
    ];

    return <form onSubmit={handleSubmit(onSubmit)} className="create-tax-return-form">
        <Select
            options={yearsAvailable}
            placeholder={t('CreateTaxReturnForm.year.placeholder')}
            {...register('year', {
                required: t('CreateTaxReturnForm.year.required'),
            })}
            error={getError('year')}
        >{t('CreateTaxReturnForm.year.label')}</Select>
        <TextInput
            {...register('firstName', {
                required: t('CreateTaxReturnForm.firstName.required'),
            })}
            placeholder={t('CreateTaxReturnForm.firstName.placeholder')}
            error={getError('firstName')}
        >
            {t('CreateTaxReturnForm.firstName.label')}
        </TextInput>
        <TextInput
            {...register('lastName', {
                required: t('CreateTaxReturnForm.lastName.required'),
            })}
            placeholder={t('CreateTaxReturnForm.lastName.placeholder')}
            error={getError('lastName')}
        >
            {t('CreateTaxReturnForm.lastName.label')}
        </TextInput>
        <Submit>
            {t('CreateTaxReturnForm.submit.label')}
        </Submit>
    </form>

}
