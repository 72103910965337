import classNames from "classnames";
import {DetailedHTMLProps, forwardRef, Fragment, SelectHTMLAttributes} from "react";

import {FCWithChildren} from "@app/common/model/FCWithChildren";

import './select.scss';

export type SelectOption = {
    value: string;
    label: string;
}

export type SelectProps = DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
    options: SelectOption[];
    error?: string;
    placeholder?: string;
    className?: string;
}

export const Select: FCWithChildren<SelectProps> = forwardRef(({children, options, error, placeholder, className, ...selectProps}, ref) => {
    return <Fragment>
        <div className={classNames('select', error ? 'select--with-error' : null, className)}>
            <label>
                <div className="select__label">{children}</div>
                <select {...selectProps} ref={ref}>
                    {placeholder && <option value="">{placeholder}</option>}
                    {options.map((option) => (
                        <option value={option.value} key={option.value}>{option.label}</option>
                    ))}
                </select>
            </label>
        </div>
        {error && <div className="select__error">{error}</div>}
    </Fragment>
});

Select.displayName = 'Select';
