import {FC} from "react";

import {SelectOption} from "@app/common/components/form/Select/Select";
import {VzpBranch} from "@app/common/model/TaxReturn/VzpBranch";
import {SelectField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/SelectField";
import {useFormFieldCb} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormFieldCb";
import {FieldComponentProps} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FieldComponent";

export const VzpBranchSelect: FC<FieldComponentProps> = ({name}) => {
    const formFieldCb = useFormFieldCb();

    const selectOptions: SelectOption[] = [];
    for (const vzpBranch in VzpBranch) {
        if (Object.prototype.hasOwnProperty.call(VzpBranch, vzpBranch)) {
            const formField = formFieldCb(`healthInsurance.vzpBranches.${vzpBranch as VzpBranch}`);
            selectOptions.push({value: vzpBranch, label: formField.label})
        }
    }

    return <SelectField name={name} options={selectOptions} customFormField={formFieldCb('healthInsurance.vzpBranch')}/>
}
