import {FC} from "react";

import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {LabelWithHelp} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/LabelWithHelp/LabelWithHelp";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";
import {
    SelfEmploymentStatsExtraFields
} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/SelfEmploymentStatsExtraFields";

export type SelfEmploymentStatsEditItemProps = {
    name: keyof SelfEmploymentStatsExtraFields;
    optional?: boolean;
}

export const SelfEmploymentStatsEditItem: FC<SelfEmploymentStatsEditItemProps> = ({name, optional}) => {
    const atCommencementFormField = useFormField('selfEmployment.selfEmploymentStats.atCommencement');
    const atEndFormField = useFormField('selfEmployment.selfEmploymentStats.atEnd');

    return <FieldsRow className="self-employment-stats-edit-item">
        <LabelWithHelp name={`selfEmployment.selfEmploymentStats.${name}`} />
        <AmountField
            name={`selfEmployment.selfEmploymentStats.${name}AtCommencement`}
            customFormField={atCommencementFormField}
            optional={optional}
        />
        <AmountField
            name={`selfEmployment.selfEmploymentStats.${name}AtEnd`}
            customFormField={atEndFormField}
            optional={optional}
        />
    </FieldsRow>

}
