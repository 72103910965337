import {FC} from "react";
import {get, GlobalError, useFormContext} from "react-hook-form";

import {RequiredStar} from "@app/common/components/form/RequiredStar/RequiredStar";
import {TextInput} from "@app/common/components/form/TextInput/TextInput";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {HelpIcon} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/HelpIcon/HelpIcon";
import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";
import {FieldComponentProps} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FieldComponent";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";

export type TextFieldProps = FieldComponentProps & {
    customFormField?: FormField;
    customDisabled?: boolean;
}

export const TextField: FC<TextFieldProps> = ({name, optional = false, customFormField, customDisabled}) => {
    const editing = useEditingContext();
    const {register, formState} = useFormContext<TaxReturn>();

    const defaultFormField = useFormField(name);
    const formField = customFormField || defaultFormField;

    const error = get(formState.errors, name) as GlobalError|undefined;

    return <div className="text-field">
        <TextInput
            {...register(name)}
            disabled={customDisabled || !editing}
            placeholder={formField.placeholder}
            error={error?.message}
        >
            {formField.label}
            <RequiredStar optional={optional} />
            <HelpIcon formField={formField}/>
        </TextInput>
    </div>
};
