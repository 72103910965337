import {FC, Fragment} from "react";
import {useWatch} from "react-hook-form";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {CheckboxField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxField/CheckboxField";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {
    NumberOfMonthsField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumberOfMonthsField/NumberOfMonthsField";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

export const Marriage: FC = () => {
    const commonHouseholdSelected = useWatch<TaxReturn>({name: 'sections.commonHousehold'}) as boolean;
    const partnersSelected = useWatch<TaxReturn>({name: 'sections.partners'}) as boolean;

    return <CheckboxGroup name="sections.married">
        <CheckboxField name="sections.commonHousehold" />
        <CheckboxField name="sections.partners" />
        {commonHouseholdSelected && partnersSelected && <Fragment>
            <FieldsRow>
                <TextField name="deductibles.marriage.spouseFirstName" />
                <TextField name="deductibles.marriage.spouseLastName" />
                <TextField name="deductibles.marriage.birthNumber" />
            </FieldsRow>
            <FieldsRow>
                <NumberOfMonthsField name="deductibles.marriage.numberOfMonths" />
                <CheckboxField name="deductibles.marriage.ztpLicense" />
            </FieldsRow>
        </Fragment>}
    </CheckboxGroup>
}
