import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {Currency} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/Currency";

export const useCurrenciesApi = () => {
    const {i18n} = useTranslation();

    const api = useApi<Currency[]>();

    return useCallback(() => {
        return api(
            {url: `/catalogs/currency/${i18n.language.split('-').shift()}`, method: HttpMethod.GET},
        );
    }, [api, i18n.language]);
}
