import {FC, useEffect, useState} from "react";

import {SelectOption} from "@app/common/components/form/Select/Select";
import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {SelectField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/SelectField";
import {useCurrenciesApi} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useCurrenciesApi";
import {Currency} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/Currency";
import {FieldComponentProps} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FieldComponent";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";

export type CurrencySelectFieldProps = FieldComponentProps & {
    customFormField?: FormField;
}

export const CurrencySelectField: FC<CurrencySelectFieldProps> = ({name, customFormField}) => {
    const [currencies, setCurrencies] = useState<Currency[]|null>(null);

    const showErrorToast= useShowErrorToast();
    const currenciesApi = useCurrenciesApi();

    useEffect(() => {
        if (!currencies) {
            currenciesApi()
                .then((setCurrencies))
                .catch((error: unknown) => {
                    showErrorToast(error as Error);
                    setCurrencies([]);
                });
        }
    }, [currencies, currenciesApi, showErrorToast]);

    const currenciesOptions: SelectOption[] = currencies
        ? currencies.map((currency) => ({value: currency.code, label: currency.label}))
        : [];

    if (!currenciesOptions.length) {
        return null;
    }

    return <SelectField name={name} options={currenciesOptions} customFormField={customFormField} />
}
