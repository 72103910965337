import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {SocialSecurityOffice} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/SocialSecurityOffice";

export const useSocialSecurityOfficesApi = () => {
    const {i18n} = useTranslation();

    const api = useApi<SocialSecurityOffice[]>();

    return useCallback(() => {
        return api(
            {url: `/catalogs/social-welfare-office/${i18n.language.split('-').shift()}`, method: HttpMethod.GET},
        );
    }, [api, i18n.language]);
}
