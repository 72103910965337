import {FC} from "react";
import {useWatch} from "react-hook-form";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {TaxReturnState} from "@app/common/model/TaxReturn/TaxReturnState";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {NumericField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/NumericField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {ReadOnlyFlag} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/ReadOnlyFlag/ReadOnlyFlag";
import {
    ExpensesTypeSelect
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/SelfEmploymentEdit/ExpensesTypeSelect/ExpensesTypeSelect";
import {
    OtherActivities
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/SelfEmploymentEdit/OtherActivities/OtherActivities";
import {
    SelfEmploymentBeforeYouStart
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/SelfEmploymentEdit/SelfEmploymentBeforeYouStart/SelfEmploymentBeforeYouStart";
import {
    SelfEmploymentStatsEdit
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/SelfEmploymentEdit/SelfEmploymentStatsEdit/SelfEmploymentStatsEdit";
import {
    TradeLicenseEdit
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/SelfEmploymentEdit/TradeLicenseEdit/TradeLicenseEdit";
import {
    TypeOfActivitySelect
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/SelfEmploymentEdit/TypeOfActivitySelect/TypeOfActivitySelect";

import './selfEmploymentEdit.scss';

export const SelfEmploymentEdit: FC = () => {
    const taxReturnState = useWatch<TaxReturn>({name: 'state'}) as TaxReturnState;
    const isPaid = taxReturnState ===  TaxReturnState.PAID;

    return <CheckboxGroup name="sections.selfEmployment" className="self-employment-edit">
        <SelfEmploymentBeforeYouStart />
        <FieldsRow>
            <div>
                <NumericField name="selfEmployment.tradeLicense.ico" customDisabled={isPaid} />
                <ReadOnlyFlag name="selfEmployment.tradeLicense.ico" />
            </div>
        </FieldsRow>
        <FieldsRow>
            <TypeOfActivitySelect name="selfEmployment.prevailingActivityName" />
            <AmountField name="selfEmployment.income" />
            <ExpensesTypeSelect name="selfEmployment.expensesType" />
        </FieldsRow>
        <OtherActivities />
        <TradeLicenseEdit />
        <SelfEmploymentStatsEdit />
    </CheckboxGroup>
}
