import {FC, useEffect, useMemo, useRef, useState} from "react";
import {useWatch} from "react-hook-form";

import { SelectOption} from "@app/common/components/form/Select/Select";
import {Loading} from "@app/common/components/Loading/Loading";
import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {SelectField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/SelectField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {useTaxOfficesApi} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useTaxOfficesApi";
import {TaxOffice} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxOffice";

export const TaxOfficesEdit: FC = () => {
    const [taxOffices, setTaxOffices] = useState<TaxOffice[]|null>(null);
    const regionalOfficeRef = useRef<HTMLSelectElement>(null);

    const showErrorToast= useShowErrorToast();
    const taxOfficesApi = useTaxOfficesApi();

    useEffect(() => {
        if (!taxOffices) {
            taxOfficesApi()
                .then((setTaxOffices))
                .catch((error: unknown) => {
                    showErrorToast(error as Error);
                    setTaxOffices([]);
                });
        }
    }, [showErrorToast, taxOffices, taxOfficesApi]);

    const taxOffice = useWatch<TaxReturn>({name: 'taxOffice'}) as string;

    useEffect(() => {
        if (regionalOfficeRef.current) {
            regionalOfficeRef.current.focus();
        }

    }, [taxOffice]);

    const taxOfficesOptions: SelectOption[] = taxOffices
        ? taxOffices.map((taxOfficeItem) => ({value: taxOfficeItem.code, label: taxOfficeItem.label}))
        : [];

    const regionalOfficesOptions: SelectOption[] = useMemo(() => {
        if (!taxOffices || !taxOffice || taxOffice === '') {
            return [];
        }

        const foundTaxOffice = taxOffices.find((taxOfficeItem) => taxOfficeItem.code === taxOffice);
        if (!foundTaxOffice) {
            return [];
        }

        return foundTaxOffice.regionalOffices.map<SelectOption>((regionalOffice) => ({
            value: regionalOffice.code,
            label: regionalOffice.label,
        }));
    }, [taxOffices, taxOffice]);

    return <Loading active={!taxOffices} className="tax-offices-select">
        <FieldsRow>
            {taxOfficesOptions.length > 0 && <SelectField name="taxOffice" options={taxOfficesOptions} />}
            {regionalOfficesOptions.length > 0 && <SelectField name="regionalOffice" options={regionalOfficesOptions} />}
        </FieldsRow>
    </Loading>
};
