import {useCallback, useRef} from "react";

export const useFocusFirstInput = () => {
    const containerRef = useRef<HTMLDivElement>(null);

    const focus = useCallback((customSelector?: string) => {
        if (containerRef.current) {
            const firstInput = containerRef.current.querySelector(
                customSelector || 'input:not(:disabled):not(:read-only),select:not(:disabled)'
            );
            if (firstInput) {
                (firstInput as HTMLInputElement).focus();
            }
        }
    }, []);

    return {
        containerRef,
        focus,
    }
}
