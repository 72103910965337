import {FC} from "react";
import {get, GlobalError, useFormContext} from "react-hook-form";

import {Checkbox} from "@app/common/components/form/Checkbox/Checkbox";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {HelpIcon} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/HelpIcon/HelpIcon";
import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";
import {FieldComponentProps} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FieldComponent";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";

import './checkboxField.scss';

export type CheckboxFieldProps = FieldComponentProps & {
    customDisabled?: boolean
    customFormField?: FormField;
}

export const CheckboxField: FC<CheckboxFieldProps> = ({name, customDisabled, customFormField, formFieldPlaceholders}) => {
    const editing = useEditingContext();
    const {register, formState} = useFormContext<TaxReturn>();

    const defaultFormField = useFormField(name, true, formFieldPlaceholders);
    const formField = customFormField || defaultFormField;

    const error = get(formState.errors, name) as GlobalError|undefined;

    return <div className="checkbox-field">
        <div className="checkbox-field__title">
            <Checkbox
                {...register(name)}
                disabled={customDisabled || !editing}
                error={error?.message}
            >
                {formField.label}
            </Checkbox>
            <HelpIcon formField={formField}/>
        </div>
        {formField.placeholder && formField.placeholder !== '' && <div className="checkbox-group__subtitle">{formField.placeholder}</div>}
    </div>
}
