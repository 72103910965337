import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {TaxReturnCalculation} from "@app/common/model/TaxReturn/TaxReturnCalculation";
import {CheckboxField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxField/CheckboxField";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";

export type HealthInsuranceInfoProps = {
    calculation: TaxReturnCalculation;
}

export const HealthInsuranceInfo: FC<HealthInsuranceInfoProps> = ({calculation}) => {
    const {t} = useTranslation();

    const returnRequiredFormField = useFormField('healthInsurance.returnRequired');

    if (!calculation.health) {
        return null;
    }

    return <Fragment>
        <div className="tax-return-edit-section__group tax-info">
            <div className="group-header">
                <p className="tax-info__tax-is">{t('HealthInsuranceInfo.healthIs', {amount: calculation.health })}</p>
                <p>{t('HealthInsuranceInfo.explanation')}</p>
            </div>
        </div>
        {calculation.health < 0 && <CheckboxField
            name="socialInsurance.healthInsurance.returnRequired"
            customFormField={returnRequiredFormField}
        />}
    </Fragment>

}
