import {FC, useEffect} from "react";
import { Route, Routes, useNavigate, useParams} from "react-router-dom";

import {TaxReturnTitle} from "@app/common/components/TaxReturnTitle/TaxReturnTitle";
import {useValidatedTaxReturns} from "@app/common/layouts/SecuredLayout/hooks/useValidatedTaxReturns";
import {childPath} from "@app/common/utils/childPath";
import {DashboardPagePath} from "@app/Dashboard/DashboardPage";
import {
    TaxReturnDownloadPage,
    TaxReturnDownloadPagePath, TaxReturnDownloadPageTitle
} from "@app/TaxReturnDetail/TaxReturnDownload/TaxReturnDownloadPage";
import {
    TaxReturnEditPage,
    TaxReturnEditPagePath,
    TaxReturnEditPageTitle
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxReturnEditPage";

import './taxReturnDetailLayout.scss';

export const TaxReturnDetailLayout: FC = () => {
    const {id: taxReturnId} = useParams<'id'>();
    const navigate = useNavigate();

    const {validatedTaxReturns} = useValidatedTaxReturns();

    const validatedTaxReturn = validatedTaxReturns.find(
        (validatedTaxReturn) => validatedTaxReturn.taxReturn.id === taxReturnId
    );

    useEffect(() => {
        if (!validatedTaxReturn) {
            navigate(DashboardPagePath);
        }
    }, [navigate, validatedTaxReturn]);

    if (!validatedTaxReturn) {
        return null;
    }

    const {taxReturn} = validatedTaxReturn;

    return <div className="tax-return-detail-page">
        <TaxReturnTitle taxReturn={taxReturn} />
        <div>TODO detail navigation</div>
        <Routes>
            <Route
                path={childPath(TaxReturnDetailPagePath, TaxReturnEditPagePath)}
                element={<TaxReturnEditPage validatedTaxReturn={validatedTaxReturn} />}
                handle={{title: TaxReturnEditPageTitle}}
            />
            <Route
                path={childPath(TaxReturnDetailPagePath, TaxReturnDownloadPagePath)}
                element={<TaxReturnDownloadPage taxReturn={taxReturn} />}
                handle={{title: TaxReturnDownloadPageTitle}}
            />
        </Routes>
    </div>
}

export const TaxReturnDetailPageTitle = 'TaxReturnDetail.title';

export const TaxReturnDetailPagePath = '/tax-return/:id/';
