import {useContext} from "react";

import {
    TaxReturnsContext,
    ValidatedTaxReturnsContextType
} from "@app/common/layouts/SecuredLayout/contexts/TaxReturnsContext/TaxReturnsContext";

export const useValidatedTaxReturns = (): ValidatedTaxReturnsContextType => {
    const validatedTaxReturns = useContext(TaxReturnsContext);

    if (!validatedTaxReturns) {
        throw new Error('Trying to use an uninstantiated TaxReturnsContext');
    }

    return validatedTaxReturns;
}
