import {FC, Fragment} from "react";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";

import './taxReturnTitle.scss';

export type TaxReturnTitleProps = {
    taxReturn: TaxReturn;
    inline?: boolean;
}

export const TaxReturnTitle: FC<TaxReturnTitleProps> = ({taxReturn, inline = false}) => {
    if (inline) {
        return <Fragment>
            {taxReturn.year}{' '}
            {taxReturn.personalData ? `${taxReturn.personalData.firstName || ''} ${taxReturn.personalData.lastName || ''}` : ''}
        </Fragment>
    }

    return <div className="tax-return-title">
        <div className="tax-return-title__year">{taxReturn.year}</div>
        {taxReturn.personalData && <div className="tax-return-title__name">
            {`${taxReturn.personalData.firstName || ''} ${taxReturn.personalData.lastName || ''}`}
        </div>}
    </div>

}
