import {FC} from "react";

import {HelpPopup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/HelpPopup";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";
import {FormFieldPath} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormFieldsResponse";

import './helpLink.scss';

export type HelpLinkProps = {
    name: FormFieldPath;
}

export const HelpLink: FC<HelpLinkProps> = ({name}) => {
    const formField = useFormField(name);

    return <HelpPopup formField={formField} className="help-link">
        {formField.label}
    </HelpPopup>
}
