import {FC} from "react";

import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {
    CountrySelectField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/CountrySelectField/CountrySelectField";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

export const NonCzTaxResidentEdit: FC = () => {
    return <CheckboxGroup name="sections.nonCzTaxResident">
        <FieldsRow>
            <CountrySelectField name="nonCzTaxResident.country" />
            <TextField name="nonCzTaxResident.passportNumber" />
            <AmountField name="nonCzTaxResident.worldWideIncome" />
        </FieldsRow>
    </CheckboxGroup>
}
