import {FC} from "react";
import {useWatch} from "react-hook-form";

import {LeaseIncomeExpensesType} from "@app/common/model/TaxReturn/LeaseIncomeExpensesType";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {
    LeaseExpensesTypeSelect
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/LeaseIncomeEdit/LeaseExpensesTypeSelect/LeaseExpensesTypeSelect";

export const LeaseIncomeEdit: FC = () => {
    const leaseExpensesType = useWatch<TaxReturn>({name: 'leaseIncome.expensesType'}) as LeaseIncomeExpensesType|undefined;

    return <CheckboxGroup name="sections.leaseIncome">
        <FieldsRow>
            <AmountField name="leaseIncome.totalIncome" />
            <AmountField name="leaseIncome.realEstateIncome" optional={true} />
        </FieldsRow>
        <FieldsRow>
            <LeaseExpensesTypeSelect name="leaseIncome.expensesType" />
            {leaseExpensesType === LeaseIncomeExpensesType.REAL && <AmountField name="leaseIncome.expenses" />}
        </FieldsRow>
    </CheckboxGroup>
}
