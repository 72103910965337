import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC, useCallback} from "react";
import {useFieldArray} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {Button} from "@app/common/components/Button/Button";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {HelpLink} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/HelpLink/HelpLink";
import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";
import {BeforeYouStart} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/BeforeYouStart/BeforeYouStart";
import {EmploymentEdit} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/Employments/EmploymentEdit/EmploymentEdit";

export const Employments: FC = () => {
    const {t} = useTranslation();
    const editing = useEditingContext();

    const {fields, append, remove} = useFieldArray<TaxReturn, 'employments'>({name: 'employments'});

    const onEmploymentAdd = useCallback(() => {
        append({});
    }, [append]);

    return <CheckboxGroup name="sections.employments">
        <BeforeYouStart>
            <HelpLink name="employments.0.before.certificate_of_taxable_income" />
        </BeforeYouStart>
        {fields.map((field, index) => (
            <EmploymentEdit index={index} onRemove={editing && fields.length > 1 ? remove : undefined} key={field.id} />
        ))}
        {editing && <Button onClick={onEmploymentAdd} variant="secondary">
            <FontAwesomeIcon icon={faPlus} />{' '}
            {t('Employments.actions.add')}
        </Button>}
    </CheckboxGroup>
}
