import {FC} from "react";

import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

export const Donation: FC = () => {
    return <CheckboxGroup name="sections.donation">
        <FieldsRow>
            <AmountField name="deductibles.donation" />
        </FieldsRow>
    </CheckboxGroup>
}
