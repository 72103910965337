import {useContext, useMemo} from "react";
import {FieldPath, get, useWatch} from "react-hook-form";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {FormFieldsContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/contexts/FormFieldsContext";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";
import {TaxReturnWithExtraFormFields} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormFieldsResponse";

export type FormFieldPlaceHolders = {
    placeholder: string|RegExp;
    value: string;
}[];

export const useFormField = (name: FieldPath<TaxReturnWithExtraFormFields>, skipArrays: boolean = true) => {
    const formFields = useContext(FormFieldsContext);
    const year = useWatch<TaxReturn>({name: 'year'}) as number;

    if (!formFields) {
        throw new Error('Trying to use an uninstantiated FormFieldsContext');
    }

    const formField = get(formFields, skipArrays ? name.replace(/[0-9]+\./, '') : name) as FormField;

    const placeholders = useMemo<FormFieldPlaceHolders>(() => [
        {placeholder: /{{taxReturnYear}}/g, value: year.toString(10)},
        {placeholder: /{{followingYear}}/g, value: (year + 1).toString(10)},
    ], [year]);

    if (formField) {
        placeholders.forEach(({placeholder, value}) => {
            formField.label = formField.label.replace(placeholder, value);
            formField.placeholder = formField.placeholder ? formField.placeholder.replace(placeholder, value) : undefined;
            formField.helpText = formField.helpText ? formField.helpText.replace(placeholder, value) : undefined;
        });
    }

    return formField;
}
