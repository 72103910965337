import {useCallback, useContext} from "react";
import {FieldPath, get} from "react-hook-form";

import {FormFieldsContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/contexts/FormFieldsContext";
import {FormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormField";
import {TaxReturnWithExtraFormFields} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormFieldsResponse";

export const useFormFieldCb = () => {
    const formFields = useContext(FormFieldsContext);

    if (!formFields) {
        throw new Error('Trying to use an uninstantiated FormFieldsContext');
    }

    return useCallback((name: FieldPath<TaxReturnWithExtraFormFields>, skipArrays: boolean = true) => {
        return get(formFields, skipArrays ? name.replace(/[0-9]+\./, '') : name) as FormField;
    }, [formFields]);

}
