import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import {Alert} from "@app/common/components/Alert/Alert";
import {Button} from "@app/common/components/Button/Button";
import {Loading} from "@app/common/components/Loading/Loading";
import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {
    TaxReturnsContext, UpdateTaxReturnCallback,
    ValidatedTaxReturnsContextType
} from "@app/common/layouts/SecuredLayout/contexts/TaxReturnsContext/TaxReturnsContext";
import {useUser} from "@app/common/layouts/SecuredLayout/hooks/useUser";
import {useValidatedTaxReturnsApi} from "@app/common/layouts/SecuredLayout/hooks/useValidatedTaxReturnsApi";
import {FCWithChildren} from "@app/common/model/FCWithChildren";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {ValidatedTaxReturn} from "@app/common/model/ValidatedTaxReturn";
import {TaxReturnValidationTarget} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnValidationTarget";
import {useRecalculateTaxReturnApi} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/hooks/useRecalculateTaxReturnApi";

export const TaxReturnsContextProvider: FCWithChildren = ({children}) => {
    const {t} = useTranslation();

    const [validatedTaxReturns, setValidatedTaxReturns] = useState<ValidatedTaxReturn[]|null|false>(null);
    const [recalculating, setRecalculating] = useState<boolean>(false);
    const isLoading = useRef<boolean>(false);

    const user = useUser();
    const validatedTaxReturnsApi = useValidatedTaxReturnsApi();
    const recalculateTaxReturnApi = useRecalculateTaxReturnApi();
    const showErrorToast = useShowErrorToast();

    const reloadTaxReturns = useCallback(async () => {
        if (isLoading.current) {
            return;
        }

        isLoading.current = true
        try {
            const response = await validatedTaxReturnsApi(user);
            setValidatedTaxReturns(response);
        } catch (error: unknown) {
            showErrorToast(error as Error);
            setValidatedTaxReturns(false);
        } finally {
            isLoading.current = false;
        }
    }, [showErrorToast, user, validatedTaxReturnsApi]);

    const recalculate = useCallback(async (taxReturnId: string) => {
        setRecalculating(true);
        try {
            return await recalculateTaxReturnApi(taxReturnId);
        } catch (error: unknown) {
            showErrorToast(error as Error);
            return false;
        } finally {
            setRecalculating(false);
        }
    }, [recalculateTaxReturnApi, showErrorToast]);

    const updateTaxReturn = useCallback<UpdateTaxReturnCallback>(async (updatedTaxReturn, validatedTarget, valid) => {
        if (!validatedTaxReturns) {
            return;
        }

        const calculation = await recalculate(updatedTaxReturn.id)

        const newValidatedTaxReturns: ValidatedTaxReturn[] = validatedTaxReturns.map(validatedTaxReturn => {
            if (validatedTaxReturn.taxReturn.id !== updatedTaxReturn.id) {
                return validatedTaxReturn;
            }

            const validation: TaxReturnValidationTarget[] = validatedTaxReturn.validation;
            if (valid && validation.includes(validatedTarget)) {
                validation.splice(validation.indexOf(validatedTarget), 1);
            }
            if (!valid && !validation.includes(validatedTarget)) {
                validation.push(validatedTarget);
            }

            if (calculation !== false)  {
                updatedTaxReturn.calculation = calculation;
            }

            return {
                taxReturn: updatedTaxReturn,
                validation,
            }
        });

        setValidatedTaxReturns(newValidatedTaxReturns);
    }, [recalculate, validatedTaxReturns])

    const createTaxReturn = useCallback((newTaxReturn: TaxReturn) => {
        setValidatedTaxReturns([
            {
                taxReturn: newTaxReturn,
                validation: [
                    TaxReturnValidationTarget.PERSONAL_DATA,
                    TaxReturnValidationTarget.ALL,
                ],
            },
            ...(validatedTaxReturns || []),
        ]);
    }, [validatedTaxReturns]);

    useEffect(() => {
        if (validatedTaxReturns === null) {
            void reloadTaxReturns();
        }
    }, [reloadTaxReturns, validatedTaxReturns]);

    const contextValue: ValidatedTaxReturnsContextType = useMemo(() => ({
        validatedTaxReturns: validatedTaxReturns || [],
        updateTaxReturn,
        recalculating,
        createTaxReturn
    }), [createTaxReturn, recalculating, updateTaxReturn, validatedTaxReturns]);

    if (validatedTaxReturns === null) {
        return <Loading active={true} />
    }

    if (validatedTaxReturns === false) {
        return <div>
            <Alert type="error">{t('common.error.loading.description')}</Alert>
            <Button variant="error" onClick={reloadTaxReturns}>{t('common.error.loading.action')}</Button>
        </div>
    }

    return <TaxReturnsContext.Provider value={contextValue} >
        {children}
    </TaxReturnsContext.Provider>
}
