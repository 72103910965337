import {FC, Fragment} from "react";
import {useWatch} from "react-hook-form";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {CheckboxField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxField/CheckboxField";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {
    NumberOfMonthsField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumberOfMonthsField/NumberOfMonthsField";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

export const Mortgage: FC = () => {
    const mortgageRentSelected = useWatch<TaxReturn>({name: 'sections.mortgageRent'}) as boolean;

    return <CheckboxGroup name="sections.mortgage">
        <CheckboxField name="sections.mortgageRent" />
        {!mortgageRentSelected && <Fragment>
            <CheckboxField name="deductibles.mortgage.after2021" />
            <FieldsRow>
                <AmountField name="deductibles.mortgage.interests" />
                <NumberOfMonthsField name="deductibles.mortgage.numberOfMonths" />
            </FieldsRow>
        </Fragment>}
    </CheckboxGroup>

}
