import classNames from "classnames";
import {forwardRef} from "react";
import {Link, LinkProps} from "react-router-dom";

import {ButtonVariant} from "@app/common/components/Button/Button";
import {FCWithChildren} from "@app/common/model/FCWithChildren";

export type LinkButtonProps = LinkProps & {
    variant?: ButtonVariant;
};

export const LinkButton: FCWithChildren<LinkButtonProps> = forwardRef<HTMLAnchorElement, LinkButtonProps>(({children, variant, className, ...linkProps}, ref) => {
    return <Link {...linkProps} className={classNames('button', `button--${variant}`, className)} ref={ref}>
        {children}
    </Link>
});

LinkButton.displayName = 'LinkButton';
