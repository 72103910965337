import {FC} from "react";
import {renderToString} from "react-dom/server";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import striptags from "striptags";

import {TaxReturnStepTitle} from "@app/common/components/TaxReturnStepTitle/TaxReturnStepTitle";
import {ValidatedTaxReturn} from "@app/common/model/ValidatedTaxReturn";
import {taxReturnSectionTo} from "@app/TaxReturnDetail/TaxReturnEdit/common/utils/taxReturnSectionTo";
import {stepForValidationTarget} from "@app/TaxReturnDetail/TaxReturnEdit/common/utils/validationTargetForStep";

import './incompleteTaxReturn.scss';

export type IncompleteTaxReturnProps = {
    validatedTaxReturn: ValidatedTaxReturn;
}

export const IncompleteTaxReturn: FC<IncompleteTaxReturnProps> = ({validatedTaxReturn}) => {
    const {t} = useTranslation();

    return <div className="tax-return-edit-section__group incomplete-tax-return">
        <div className="group-header">
            <div className="group-header__title">{t('IncompleteTaxReturn.title')}</div>
            <div className="group-header__subtitle">{t('IncompleteTaxReturn.subtitle')}</div>
        </div>
        {validatedTaxReturn.validation.map((target) => {
            const step  = stepForValidationTarget(target);

            return <Link
                to={taxReturnSectionTo(validatedTaxReturn.taxReturn.id, step, true)}
                key={target}
            >
                {t('IncompleteTaxReturn.link', {
                    section: striptags(renderToString(<TaxReturnStepTitle step={step} />))
                })}
            </Link>
        })}
    </div>
}
