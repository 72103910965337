import {createContext} from "react";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {ValidatedTaxReturn} from "@app/common/model/ValidatedTaxReturn";
import {TaxReturnValidationTarget} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnValidationTarget";

export type UpdateTaxReturnCallback = {
    (taxReturn: TaxReturn, validatedTarget: TaxReturnValidationTarget, valid: boolean): Promise<void>;
}

export type ValidatedTaxReturnsContextType = {
    validatedTaxReturns: ValidatedTaxReturn[];
    updateTaxReturn: UpdateTaxReturnCallback;
    createTaxReturn: (newTaxReturn: TaxReturn) => void;
    recalculating: boolean;
}

export const TaxReturnsContext = createContext<ValidatedTaxReturnsContextType|undefined>(undefined);
