import {FC} from "react";
import {useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {Button} from "@app/common/components/Button/Button";
import {OtherIncomeType} from "@app/common/model/TaxReturn/OtherIncomeType";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {
    OtherIncomesTypeSelect
} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/OtherIncomes/OtherIncomeTypeSelect/OtherIncomesTypeSelect";

import './otherIncomeEdit.scss';

export type OtherIncomeEditProps = {
    index: number;
    onRemove?: (index: number) => void;
}

export const OtherIncomeEdit: FC<OtherIncomeEditProps> = ({index, onRemove}) => {
    const {t} = useTranslation();

    const incomeType = useWatch<TaxReturn>({name: `otherIncomes.${index}.type`}) as OtherIncomeType|undefined;

    return <div className="other-income-edit">
        <FieldsRow>
            <OtherIncomesTypeSelect name={`otherIncomes.${index}.type`} />
            {incomeType === OtherIncomeType.OTHER && <TextField name={`otherIncomes.${index}.name`} />}
        </FieldsRow>
        <FieldsRow>
            <AmountField name={`otherIncomes.${index}.income`} />
            <AmountField name={`otherIncomes.${index}.expenses`} />
            {onRemove && <div className="other-income-edit__remove">
                <Button onClick={() => onRemove(index)} variant="secondary">
                    {t('OtherIncomeEdit.actions.remove')}
                </Button>
            </div>}
        </FieldsRow>
    </div>

}
