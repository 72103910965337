import {FC, useEffect, useState} from "react";
import {FieldPath} from "react-hook-form";

import {SelectOption} from "@app/common/components/form/Select/Select";
import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {SelectField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/SelectField";
import {useSocialSecurityOfficesApi} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useSocialSecurityOfficesApi";
import {SocialSecurityOffice} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/SocialSecurityOffice";

export type SocialSecurityOfficeSelectProps = {
    name: FieldPath<TaxReturn>;
}

export const SocialSecurityOfficeSelect: FC<SocialSecurityOfficeSelectProps> = ({name}) => {
    const [offices, setOffices] = useState<SocialSecurityOffice[]|null>(null);

    const showErrorToast= useShowErrorToast();
    const socialSecurityOfficesApi = useSocialSecurityOfficesApi();

    useEffect(() => {
        if (!offices) {
            socialSecurityOfficesApi()
                .then((setOffices))
                .catch((error: unknown) => {
                    showErrorToast(error as Error);
                    setOffices([]);
                });
        }
    }, [offices, showErrorToast, socialSecurityOfficesApi]);

    const socialSecurityOfficesOptions: SelectOption[] = offices
        ? offices.map((office) => ({value: office.code, label: office.label}))
        : [];

    if (!socialSecurityOfficesOptions.length) {
        return null;
    }

    return <SelectField name={name} options={socialSecurityOfficesOptions} />
}
