import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {TaxReturnValidation} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnValidation";
import {TaxReturnValidationTarget} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnValidationTarget";

type SanitizedTaxReturn = Omit<TaxReturn, 'id'|'year'|'state'|'updatedAt'|'createdAt'|'calculation'>;

export const usePatchTaxReturnApi = (taxReturnId: string, target: TaxReturnValidationTarget) => {
    const {i18n} = useTranslation();
    const api = useApi<TaxReturnValidation, SanitizedTaxReturn>();

    return useCallback(async (taxReturn: TaxReturn) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {id, year, state, updatedAt, createdAt, calculation, ...sanitizedTaxReturn} = taxReturn;

        return await api(
            {url: `/tax-returns/${taxReturnId || taxReturn.id}/validate/${target}/${i18n.language.split('-').shift()}`, method: HttpMethod.PATCH},
            sanitizedTaxReturn,
        );
    }, [api, i18n.language, target, taxReturnId]);
}
