import {faCheck, faPen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {LinkButton} from "@app/common/components/Button/LinkButton/LinkButton";
import {TaxReturnStepTitle} from "@app/common/components/TaxReturnStepTitle/TaxReturnStepTitle";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {taxReturnSectionTo} from "@app/TaxReturnDetail/TaxReturnEdit/common/utils/taxReturnSectionTo";

import './taxReturnStateButton.scss';

export type TaxReturnStateButtonProps = {
    completed: boolean;
    step: TaxReturnStep;
    taxReturn: TaxReturn;
}

export const TaxReturnStateButton: FC<TaxReturnStateButtonProps> = ({completed, step, taxReturn}) => {
    const {t} = useTranslation();

    return <LinkButton
        to={taxReturnSectionTo(taxReturn.id, step, !completed)}
        variant={completed ? 'success' : 'warning'}
        title={t(completed ? 'TaxReturnState.completed' : 'TaxReturnState.notCompleted')}
        className="tax-return-state-button"
    >
        <TaxReturnStepTitle step={step} />
        <FontAwesomeIcon icon={completed ? faCheck : faPen} />
    </LinkButton>
}
