import {FC} from "react";
import {useWatch} from "react-hook-form";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {
    NumberOfMonthsField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumberOfMonthsField/NumberOfMonthsField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

export const Student: FC = () => {
    const year = useWatch<TaxReturn>({name: 'year'}) as number;

    if (year >= 2024) {
        return null;
    }

    return <CheckboxGroup name="sections.student">
        <FieldsRow>
            <NumberOfMonthsField name="deductibles.student" />
        </FieldsRow>
    </CheckboxGroup>
}
