import {faAsterisk} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC} from "react";

import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";

import './requiredStar.scss';

export type RequiredStarProps = {
    optional?: boolean;
}

export const RequiredStar: FC<RequiredStarProps> = ({optional}) => {
    const editing = useEditingContext();

    if (!editing || optional) {
        return null;
    }

    return <FontAwesomeIcon icon={faAsterisk} className="required-star" />
}
