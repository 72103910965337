import {FC} from "react";

import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {NumericField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/NumericField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

export const LifeInsurance: FC = () => {
    return <CheckboxGroup name="sections.lifeInsurance">
        <FieldsRow>
            <NumericField name="deductibles.lifeInsurance.contracts" />
            <AmountField name="deductibles.lifeInsurance.amount" />
        </FieldsRow>
    </CheckboxGroup>
}
