import {FC, useEffect, useState} from "react";
import {Location, Outlet, useLocation, useNavigate} from "react-router-dom";

import {Breadcrumbs} from "@app/common/layouts/SecuredLayout/components/Breadcrumbs/Breadcrumbs";
import {Header} from "@app/common/layouts/SecuredLayout/components/Header/Header";
import {
    TaxReturnsContextProvider
} from "@app/common/layouts/SecuredLayout/contexts/TaxReturnsContext/TaxReturnsContextProvider";
import {UserContext} from "@app/common/layouts/SecuredLayout/contexts/UserContext";
import {useUserInfoApi} from "@app/common/layouts/SecuredLayout/hooks/useUserInfoApi";
import {User} from "@app/common/model/User";
import {LoginPagePath} from "@app/Login/LoginPage";

import './securedLayout.scss';

export const SecuredLayout: FC = () => {
    const location: Location<User | undefined> = useLocation();
    const navigate = useNavigate();

    const [user, setUser] = useState<User | undefined>(undefined);
    const userInfo = useUserInfoApi();

    useEffect(() => {
        const user = location.state;
        if (user) {
            setUser(user);
        } else {
            userInfo()
                .then((user) => {
                    setUser(user);
                })
                .catch(() => {
                    navigate(LoginPagePath);
                });
        }
    }, [location.state, navigate, userInfo])

    if (!user) {
        return null;
    }

    return <div className="secured-layout">
        <UserContext.Provider value={user}>
            <TaxReturnsContextProvider>
                <Header/>
                <div className="secured-layout__content">
                    <Breadcrumbs/>
                    <Outlet/>
                </div>
            </TaxReturnsContextProvider>
        </UserContext.Provider>
    </div>
}
