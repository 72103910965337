import {useCallback} from "react";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";

export const useLogOutApi = () => {
    const api = useApi();

    return useCallback(() => {
        return api({url: '/logout', method: HttpMethod.POST});
    }, [api]);
}
