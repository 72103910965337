import {useContext} from "react";

import {EditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/contexts/EditingContext";

export const useEditingContext = (): boolean => {
    const editingContext = useContext(EditingContext);

    if (editingContext === undefined) {
        throw new Error('Trying to use an uninstantiated EditingContext');
    }

    return editingContext;
}
