import {FC} from "react";
import {useWatch} from "react-hook-form";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";
import {AddressEdit} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/AddressEdit/AddressEdit";

export const CzechResidencyAddress: FC = () => {
    const year = useWatch<TaxReturn>({name: 'year'}) as number;

    const checkboxFormField = useFormField('sections.czechResidencyAddress', true, [{placeholder: '{{taxReturnYear}}', value: year.toString(10)}]);

    return <CheckboxGroup name="sections.czechResidencyAddress" customFormField={checkboxFormField}>
        <AddressEdit name="czechResidencyAddress" />
    </CheckboxGroup>
}
