import {FC} from "react";
import {useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";

import './noDeductiblesMessage.scss';

export type NoDeductiblesMessageProps = {
    preview: boolean;
}

export const NoDeductiblesMessage: FC<NoDeductiblesMessageProps> = ({preview}) => {
    const {t} = useTranslation();

    const sections = useWatch<TaxReturn, 'sections'>({name: 'sections'});

    const hasSomeDeductibles =
        sections.donation
        || sections.mortgage
        || sections.pensionInsurance
        || sections.lifeInsurance
        || sections.longTermCareInsurance
        || sections.longTermInvestmentProduct
        || sections.married
        || sections.children
        || sections.kindergarten
        || sections.student
        || sections.paidAdvances;

    if (!preview || hasSomeDeductibles) {
        return null;
    }

    return <p className="no-deductibles-message">
        {t('NoDeductiblesMessage.message')}
    </p>

}
