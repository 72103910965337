import {FC, Fragment} from "react";

import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

export type AddressEditProps = {
    name: 'lastDayAddress'|'czechResidencyAddress'|'permanentAddress';
}

export const AddressEdit : FC<AddressEditProps> = ({name}) => {
    return <Fragment>
        <FieldsRow>
            <TextField name={`${name}.street`} />
            <TextField name={`${name}.number`} />
        </FieldsRow>
        <FieldsRow>
            <TextField name={`${name}.city`} />
            <TextField name={`${name}.zipCode`} />
        </FieldsRow>
    </Fragment>
}
