import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {TypeOfActivity} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TypeOfActivity";

export const useTypesOfActivityApi = () => {
    const {i18n} = useTranslation();

    const api = useApi<TypeOfActivity[]>();

    return useCallback(() => {
        return api(
            {url: `/catalogs/type-of-activity/${i18n.language.split('-').shift()}`, method: HttpMethod.GET},
        );
    }, [api, i18n.language]);
}
