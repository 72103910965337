import {FC, useEffect, useState} from "react";
import {FieldPath} from "react-hook-form";

import {SelectOption} from "@app/common/components/form/Select/Select";
import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {SelectField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/SelectField";
import {useTypesOfActivityApi} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useTypesOfActivityApi";
import {TypeOfActivity} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TypeOfActivity";

export type TypeOfActivitySelectProps = {
    name: FieldPath<TaxReturn>;
}

export const TypeOfActivitySelect: FC<TypeOfActivitySelectProps> = ({name}) => {
    const [typesOfActivity, setTypesOfActivity] = useState<TypeOfActivity[]|null>(null);

    const showErrorToast= useShowErrorToast();
    const typesOfActivityApi = useTypesOfActivityApi();

    useEffect(() => {
        if (!typesOfActivity) {
            typesOfActivityApi()
                .then((setTypesOfActivity))
                .catch((error: unknown) => {
                    showErrorToast(error as Error);
                    setTypesOfActivity([]);
                });
        }
    }, [showErrorToast, typesOfActivity, typesOfActivityApi]);

    const typesOfActivityOptions: SelectOption[] = typesOfActivity
        ? typesOfActivity.map((typesOfActivityItem) => ({value: typesOfActivityItem.code, label: typesOfActivityItem.label}))
        : [];

    if (!typesOfActivityOptions.length) {
        return null;
    }

    return <SelectField name={name} options={typesOfActivityOptions} />
}
