import {FC} from "react";

import {
    CountrySelectField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/SelectField/CountrySelectField/CountrySelectField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {GroupHeader} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/GroupHeader/GroupHeader";
import {AddressEdit} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/AddressEdit/AddressEdit";

export const PermanentAddress: FC = () => {
    return <div className="tax-return-edit-section__group">
        <GroupHeader name="sections.permanentAddress" />
        <AddressEdit name="permanentAddress" />
        <FieldsRow>
            <CountrySelectField name="permanentAddress.country" />
        </FieldsRow>
    </div>
}
