import classNames from "classnames";
import {Children} from "react";

import {FCWithChildren} from "@app/common/model/FCWithChildren";

import './fieldsRow.scss';

export type FieldsRowProps = {
    className?: string;
}

export const FieldsRow: FCWithChildren<FieldsRowProps> = ({children, className}) => {
    const columns = Children.count(children);

    return <div className={classNames('fields-row', `fields-row-${columns}`, className)}>
        {children}
    </div>
}
