import classNames from "classnames";

import {Button, ButtonVariant} from "@app/common/components/Button/Button";
import {FCWithChildren} from "@app/common/model/FCWithChildren";

export type SubmitProps = {
    className?: string;
    variant?: ButtonVariant;
}

export const Submit: FCWithChildren<SubmitProps> = ({children, className, variant}) => {
    return <div className={classNames('submit', className)}>
        <Button type="submit" variant={variant}>
            {children}
        </Button>
    </div>
}
