import {FC, Fragment} from "react";
import {useWatch} from "react-hook-form";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {CheckboxField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxField/CheckboxField";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {DateField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/DateField/DateField";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

export const CorrectiveOrSupplementaryTaxReturn: FC = () => {
    const isSupplementary = useWatch<TaxReturn>({name: 'supplementaryStatement.selected'});
    const isCorrective = useWatch<TaxReturn>({name: 'correctiveTaxReturn'});

    return <Fragment>
        {!isSupplementary && <CheckboxField name="correctiveTaxReturn" hideWhenNotEditing={true} />}
        {!isCorrective && <CheckboxGroup name="supplementaryStatement.selected">
            <FieldsRow>
                <TextField name="supplementaryStatement.reason" />
                <DateField name="supplementaryStatement.date" />
                <AmountField name="supplementaryStatement.originalTax" />
            </FieldsRow>
        </CheckboxGroup>}
    </Fragment>
}
