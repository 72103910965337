import {FC} from "react";
import {useWatch} from "react-hook-form";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {TaxReturnCalculation} from "@app/common/model/TaxReturn/TaxReturnCalculation";
import {CheckboxField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxField/CheckboxField";
import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {DateField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/DateField/DateField";
import {
    AmountField
} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/AmountField/AmountField";
import {NumericField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/NumericField/NumericField";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {GroupHeader} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/GroupHeader/GroupHeader";
import {
    MonthsSelection
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/SelfEmploymentStatements/SocialInsuranceEdit/MonthsSelection/MonthsSelection";
import {
    SocialInsuranceInfo
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/SelfEmploymentStatements/SocialInsuranceEdit/SocialInsuranceInfo/SocialInsuranceInfo";
import {
    SocialSecurityOfficeSelect
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/SelfEmploymentStatements/SocialInsuranceEdit/SocialSecurityOfficeSelect/SocialSecurityOfficeSelect";

import './socialInsuranceEdit.scss';

export type SocialInsuranceEditProps = {
    calculation: TaxReturnCalculation;
}

export const SocialInsuranceEdit: FC<SocialInsuranceEditProps> = ({calculation}) => {
    const infoTypes = [
        'employed', 'parental', 'maternity', 'ill',
        'retired', 'retiredNoPayments', 'disabled', 'student', 'disabledCare'
    ] as const;

    const year = useWatch<TaxReturn>({name: 'year'}) as number;

    return <CheckboxGroup name="socialInsurance.summaryRequired" className="social-insurance-edit">
        <FieldsRow>
            <NumericField name="socialInsurance.variableSymbol" />
            <SocialSecurityOfficeSelect name="socialInsurance.socialSecurityOffice" />
            <NumericField name="socialInsurance.birthNumber" />
        </FieldsRow>
        <FieldsRow>
            <AmountField name="socialInsurance.paidAdvances" optional={true} />
        </FieldsRow>
        <div className="checkbox-group social-insurance-edit__months-group">
            <GroupHeader name="socialInsurance.monthsTitle" />
            <div className="checkbox-group__content">
                <MonthsSelection wholeMonthName="socialInsurance.selfEmployedWholeYear" monthsName="socialInsurance.selfEmployedMonths" />
            </div>
        </div>
        {infoTypes.map((type) => (
            <CheckboxGroup name={`socialInsurance.${type}.selected`} hideSubtitleWhenClosed={true} key={type} className="social-insurance-edit__months-group">
                <MonthsSelection wholeMonthName={`socialInsurance.${type}.wholeYear`} monthsName={`socialInsurance.${type}.months`} />
            </CheckboxGroup>
        ))}
        <CheckboxGroup name="socialInsurance.childCare.selected" hideSubtitleWhenClosed={true} className="social-insurance-edit__months-group">
            <MonthsSelection wholeMonthName="socialInsurance.childCare.wholeYear" monthsName="socialInsurance.childCare.months" />
            <FieldsRow>
                <NumericField name="socialInsurance.childCare.firstChildBirthNumber" />
                <NumericField name="socialInsurance.childCare.secondChildBirthNumber" optional={true} />
            </FieldsRow>
        </CheckboxGroup>
        <FieldsRow>
            {year >= 2024 && <CheckboxField name="socialInsurance.firstYearOfSelfEmployment" optional={true} />}
            <CheckboxField name="socialInsurance.nextYearSideActivity" optional={true} />
            <CheckboxField name="socialInsurance.registeredToIllnessInsurance" optional={true} />
        </FieldsRow>
        <FieldsRow>
            {year >= 2021 && <CheckboxField name="socialInsurance.submitLaterElectronically" optional={true} />}
            <CheckboxField name="socialInsurance.hasNoActiveLicense" optional={true} />
            {year >= 2022 && <CheckboxField name="socialInsurance.nextYearLumpPayer" optional={true} />}
            {year > 2018 && year < 2022 && <CheckboxField name="socialInsurance.correctiveStatement.selected" />}
        </FieldsRow>
        {year >= 2022 && <CheckboxGroup name="socialInsurance.correctiveStatement.selected" className="social-insurance-edit__months-group">
            <FieldsRow>
                <DateField name="socialInsurance.correctiveStatement.date" />
                <TextField name="socialInsurance.correctiveStatement.reason" />
            </FieldsRow>
        </CheckboxGroup>}
        <SocialInsuranceInfo calculation={calculation} />
    </CheckboxGroup>

}
