import {formatISO, parseISO} from "date-fns/fp";
import {FC, useCallback} from "react";
import {get, GlobalError, useController, useFormContext} from "react-hook-form";

import {DatePicker} from "@app/common/components/form/DatePicker/DatePicker";
import {RequiredStar} from "@app/common/components/form/RequiredStar/RequiredStar";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {HelpIcon} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/HelpPopup/HelpIcon/HelpIcon";
import {useEditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useEditingContext";
import {useFormField} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormField";
import {FieldComponentProps} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FieldComponent";

export const DateField: FC<FieldComponentProps> = ({name, optional}) => {
    const editing = useEditingContext();
    const {control, formState} = useFormContext<TaxReturn>();

    const formField = useFormField(name);
    const error = get(formState.errors, name) as GlobalError|undefined;

    const {field} = useController({name, control});

    const onDateSelect = useCallback((date: Date|null) => {
        field.onChange(date instanceof Date ? formatISO(date) : date);
    }, [field]);

    const dateValue = field.value && typeof field.value === 'string' ? parseISO(field.value) : null;

    return <div className="date-field text">
        <div className="date-field__title">
            <DatePicker
                onDateSelect={onDateSelect}
                value={dateValue}
                disabled={!editing}
                error={error?.message}
            >
                {formField.label}
                <RequiredStar optional={optional} />
                <HelpIcon formField={formField}/>
            </DatePicker>
        </div>
        {formField.placeholder && formField.placeholder !== '' && <div className="date-field__subtitle">{formField.placeholder}</div>}
    </div>
}
