import {FC} from "react";
import {useTranslation} from "react-i18next";

import {TaxReturnCalculation} from "@app/common/model/TaxReturn/TaxReturnCalculation";
import {ValidatedTaxReturn} from "@app/common/model/ValidatedTaxReturn";
import {TaxReturnValidationTarget} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnValidationTarget";
import {
    IncompleteTaxReturn
} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/components/TaxInfo/IncompleteTaxReturn/IncompleteTaxReturn";

import './taxInfo.scss';

export type TaxInfoProps = {
    validatedTaxReturn: ValidatedTaxReturn;
    calculation: TaxReturnCalculation;
}
export const TaxInfo: FC<TaxInfoProps> = ({validatedTaxReturn, calculation}) => {
    const {t} = useTranslation();

    if (validatedTaxReturn
        .validation
        .filter(target => target !== TaxReturnValidationTarget.TAX)
        .length > 0) {
        return <IncompleteTaxReturn validatedTaxReturn={validatedTaxReturn} />
    }

    return <div className="tax-return-edit-section__group tax-info">
        <div className="group-header">
            <div className="group-header__title">{t('TaxInfo.title')}</div>
            <p className="tax-info__tax-is">{t('TaxInfo.taxIs', {amount: calculation.tax })}</p>
            <p>{t('TaxInfo.explanation')}</p>
        </div>
    </div>

}
